import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { baseSocketUrl } from "@api/const";
import {
  useNarrativesContext,
  useTokensContext,
} from "@components/layout/main-layout/private-layout";
import GradeForm from "@components/pages/project/common-components/grade-form";
import CommonFooterButton from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/commonfooterbutton";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { useForm } from "antd/es/form/Form";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { DataType } from "types/data-type";
import style from "./style.module.scss";

interface GradeFormProps {}
const formQuestions = [
  {
    q: (
      <span>
        Enter the Project’s <i>context and baseline</i> below
      </span>
    ),
    result: (
      <span>
        Evaluation Report for Project’s <i>context and baseline</i> below:
      </span>
    ),
    placeholder:
      "Describe the climate vulnerabilities and impacts, GHG emissions profile, and mitigation and adaptation needs that the prospective intervention is envisaged to address.",
    limit: 500,
  },
  {
    q: (
      <span>
        Enter Your <i>Project Justification</i> Narrative Below:
      </span>
    ),
    result: (
      <span>
        Evaluation Report for <i>Project Justification</i> below:
      </span>
    ),
    placeholder:
      "Project Justification:\n\n" +
      "* Explain how the project will reduce vulnerability and promote social and economic development in the context of climate change.\n* If applicable, describe how the project will enhance infrastructure resilience and increase adaptive capacity in vulnerable communities.\n\n" +
      "Climate Linkages:\n\n" +
      "* Analyze the impacts of climate change on the sector and explain how the project will address these impacts.\n* Review existing literature and data to demonstrate the link between climate change and the impacts on the sector.\n* Explain how the project will address the root causes and support long-term sustainability.\n\n" +
      "Private Sector Involvement:\n\n" +
      "* Discuss the role of the private sector in the project and the extent of their involvement.\n* For private sector proposals, identify the key characteristics and dynamics of the sector or market in which the project/program will operate, including market size, growth potential, and key players.",
    limit: 500,
  },
  {
    q: (
      <span>
        Enter <i>Country Ownership</i> Narrative Below:
      </span>
    ),
    result: (
      <span>
        Evaluation Report for <i>Country Ownership</i> below:
      </span>
    ),
    placeholder:
      "Please indicate how the project fits in with the country’s national priorities and its full ownership of the concept. Is the project/programme directly contributing to the country’s INDC/NDC or national climate strategies or other plans such as NAMAs, NAPs or equivalent? If so, please describe which priorities identified in these documents the proposed project is aiming to address and/or improve.",
    limit: 500,
  },
  {
    q: (
      <span>
        Enter <i>Root Causes and Barriers Narrative</i> Below:{" "}
      </span>
    ),
    result: (
      <span>
        Evaluation Report for <i>Root Causes and Barriers</i> below:
      </span>
    ),
    placeholder:
      "Describe the main root causes and barriers (social, gender, fiscal, regulatory, technological, financial, ecological, institutional, etc.) that need to be addressed. Please also heed the guidance below: \n\n" +
      "Root causes and barriers: Prioritize your discussions and interventions around the most impactful root causes and barriers. Evaluate which issues, when addressed, will most significantly advance the project towards its goals and ensure its sustainability. Prioritization is key in showing strategic focus and effective resource allocation, which are often determining factors in funding decisions. This process not only streamlines project objectives but also signals to grant reviewers that the project team is capable of delivering meaningful impact\n\n" +
      "Evidence: To fortify the narrative, incorporate quantifiable evidence wherever possible. This could be data from recent studies, statistics from recognized sources, or findings from preliminary research conducted in the project areas. Quantifiable evidence lends weight to your claims about the root causes and barriers, enhancing the proposal's credibility and demonstrating a robust understanding of the problem space. \n\n",
    limit: 500,
  },
  {
    q: (
      <>
        Enter the <i>Project/Programme description</i> Below
      </>
    ),
    result: (
      <>
        Evaluation Report for <i>Project/Programme description</i> Below:
      </>
    ),
    placeholder:
      "Describe the expected set of components/outputs and sub-components/activities to address the above barriers identified that will lead to the expected outcomes. Also, please heed the guidance provided below: \n\n" +
      "Overview: The project overview should effectively outlines the initiative, its strategic components, and the anticipated outputs. Describe the expected set of components/outputs and sub-components/activities to address the above barriers identified that will lead to the expected outcomes.  It should be structured to ensure that each activity within the project is articulated clearly and succinctly, incorporating specific objectives, set timelines, and defined expected outcomes. These activities should be intelligently aligned with the overarching goals of the project, ensuring coherence across the plan. \n\n" +
      "Indicators: Impact indicators, should be well-crafted to meet distinctive requirements based on the nature of the project. For mitigation projects, it precisely includes the projected reductions in lifetime emissions, quantified in tonnes of CO2 equivalent (tCO2e), providing a clear metric for evaluating environmental benefits. Adaptation projects, on the other hand, thoroughly detail the anticipated reduction in loss of lives, safeguarding of physical assets, livelihoods, and mitigation of environmental or social losses attributed to climate-related",
    limit: 700,
  },
  {
    q: (
      <>
        Enter Project’s <i>Theory of Change Narrative</i> Below:
      </>
    ),
    result: (
      <>
        Evaluation Report for <i>Theory of Change</i> below:
      </>
    ),
    placeholder:
      "In terms of rationale, please describe the theory of change and provide information on how it serves to shift the development pathway toward a more low-emissions and/or climate resilient direction, in line with the Fund’s goals and objectives. Please heed the tips below:  \n\n" +
      "Structure: It is paramount to ensure that the Theory of Change (ToC) articulated in the grant proposal maintains a coherent and logical flow. The ToC should operate on an “if, then, because” basis, effectively mapping out the causality between project activities and the anticipated outcomes. This sequence allows reviewers to see the clear progression from planned actions to desired changes. \n\n" +
      "To enhance the narrative, each activity should be directly connected to its subsequent output and outcome, illustrating how specific actions will lead to targeted results. Furthermore, it’s essential to identify and address all potential barriers and inherent risks which could impede progress. This comprehensive view ensures that the proposal recognizes possible challenges and incorporates strategies for navigating or mitigating these barriers. \n\n" +
      "Lastly, the ToC needs to detail precisely how the project will address and overcome these identified barriers. It should describe the mechanisms through which changes will be enacted and demonstrate a clear understanding of the steps necessary to achieve transformation. Each activity listed in the ToC should be linked with a change mechanism that clearly explains how that activity contributes to overcoming the barriers.",
    limit: 500,
  },
  {
    q: (
      <>
        Enter <i>Accredited Entity and project Monitoring</i> Below:
      </>
    ),
    result: (
      <>
        Evaluation Report for <i>Accredited Entity and Project Monitoring</i>{" "}
        below:
      </>
    ),
    placeholder:
      "Describe in what way the Accredited Entity(ies) is well placed to undertake the planned activities and what will be the implementation arrangements with the executing entity(ies) and implementing partners.  Please heed the guidance below:   \n\n" +
      "Entity Identification and Overview: Ensure the Accredited Entity is clearly named, with a detailed overview of its mission reflected alongside its experience managing similar projects. This establishes credibility and capacity. \n\n" +
      "Role and Responsibility Allocation: Roles and responsibilities must be precisely defined for the Accredited Entity, Executing Entities, and Implementing Entities across each project component. Include a clear summary that outlines each partner's responsibilities to ensure accountability and clear expectations. \n\n" +
      "Implementation and Monitoring Structures: Detail the implementation arrangements, specifying interactions and operations between Executing and Implementing Entities. Ensure that monitoring structures are clearly established to oversee project progress and impacts, describing these mechanisms to highlight their effectiveness in real-time evaluation and adjustments.",
    limit: 500,
  },
  {
    q: (
      <>
        Enter the <i>the Expected Results: Impact and Paradigm Shift</i> below:
      </>
    ),
    result: (
      <>
        Evaluation Report for the{" "}
        <i>the Expected Results: Impact and Paradigm Shift</i> Below:
      </>
    ),
    limit: 500,
    placeholder:
      "Provide below an estimate of the expected project impacts aligned with the GCF investment criteria as they related to impact potential, and paradigm shift potential.   \n\n" +
      "Impact Potential: Specify the climate mitigation and/or adaptation impact, namely: \n• Mitigation core indicator: Total tons of CO2 eq to be avoided or reduced per annum. \n• Adaptation core indicator: Expected total number of direct and indirect beneficiaries and number of beneficiaries relative to total population. \n\n" +
      "Paradigm Shift Potential: The narrative should discuss the following criteria: Innovation; Scaling-up and Replication, Knowledge and Learning and highlight potential for knowledge sharing or learning at a project or institutional level. \n\n",
  },
  {
    q: (
      <>
        Enter the <i>Sustainable Development Potential</i> Below:
      </>
    ),
    result: (
      <>
        Evaluation Report for the <i>Sustainable Development Potential</i>{" "}
        Below: 
      </>
    ),
    placeholder:
      "Provide the expected environmental, social, health and economic co-benefits. \n\n" +
      "Gender related: Provide the gender-sensitive development impact, which will aim to reduce gender inequalities in climate change impacts. These co-benefits and wider positive impacts may be drawn from an economic analysis of the proposed activities and can be strengthened with more qualitative factors. \n\n" +
      "Beneficiary impact: Your narrative should thoroughly describe the benefits that would accrue to direct and indirect beneficiaries, including value chain actors. It links the project activities to national climate strategies, policies, or plans, reinforcing the project's alignment with broader environmental goals. This section should meticulously explain the methodology used to calculate beneficiary numbers, along with the assumptions made and limitations faced.",
    limit: 500,
  },
  {
    q: (
      <>
        Enter <i>Needs, Ownership & Efficiency Potential</i> Below:
      </>
    ),
    result: (
      <>
        Evaluation Report for <i>Needs, Ownership & Efficiency Potential</i>{" "}
        Below: 
      </>
    ),
    placeholder:
      "Needs of Recipient: Describe the scale and intensity of vulnerability of the country and beneficiary groups, and how the project/programme addresses these needs. Examples include, addressing the scale and intensity of exposure to climate risks for the beneficiary country and groups, including the exposure of people, social or economic assets, or capital to climate change risks. \n\n" +
      "Country Ownership: The narrative should briefly discuss the following factors: \n• Coherence and Alignment with National Climate Strategy and Priorities. \n• Detail how the project's objectives align with the priorities in the country’s national climate strategy. \n\n" +
      "Efficiency and Effectiveness:  the narrative should make the case for strong cost effectiveness and financial soundness and should provide values and supporting justification, including the calculation methodology and citations of relevant studies.",
    limit: 300,
  },
  {
    q: (
      <>
        Enter{" "}
        <i>Engagement among the NDA, AE, and/or other relevant stakeholders</i>{" "}
        below:
      </>
    ),
    result: (
      <>
        Evaluation Report for{" "}
        <i>Engagement among the NDA, AE, and/or other relevant stakeholders</i>{" "}
        Below: 
      </>
    ),
    placeholder:
      "Please describe how engagement among the NDA, AE and/or other relevant stakeholders in the country has taken place and what further engagement will be undertaken as the concept is developed into a funding proposal.\n\n" +
      "Roles and Engagement: Clearly outline the roles and contributions of all stakeholders including the National Designated Authority (NDA), private sector partners, and local communities within the project framework. Provide evidence of their active participation through meeting minutes, workshop summaries, or stakeholder agreements, underscoring their collaboration and engagement. \n\n" +
      "Stakeholder Consultations: Describe the stakeholder consultation process by identifying the consulted parties (ranging from government officials to community members), the methods used (e.g., public forums, online surveys, direct interviews), and the type of feedback received. Emphasize how this feedback influenced the project design and outline specific changes made to address the identified needs and priorities. This demonstrates a commitment to a stakeholder-driven development approach. \n\n" +
      "Beneficiary Engagement: Provide examples demonstrating how beneficiaries were engaged in the planning process, such as through focus groups, surveys, or participatory workshops. This illustrates a bottom-up approach in project design, ensuring that the interventions are closely aligned with the actual needs of the community. \n\n",
    limit: 500,
  },
  {
    q: (
      <>
        Enter <i>Indicative Finance</i> Information Below (optional):
      </>
    ),
    result: (
      <>
        Evaluation Report for <i>Indicative Finance</i> Below:
      </>
    ),
    placeholder: [
      "If you don’t have either an indicative finance narrative or image of the table to upload, then skip this section.\n\n",
    ].join(""),
    limit: 500,
  },
  {
    q: (
      <>
        Enter <i>Justification of GCF funding</i> Below:
      </>
    ),
    result: (
      <>
        Evaluation Report for <i>Justification of GCF funding</i> Below:
      </>
    ),
    placeholder:
      "Explain why the Project/ Programme requires GCF funding, i.e. explaining why this is not financed by the public and/ or private sector(s) of the country. Also, please heed the guidance below:  \n\n" +
      "Justification for GCF Appropriateness: Discuss why the Green Climate Fund (GCF) is the optimal funding source for the project. It should address the inadequacies of other funding sources, both public and private, detailing financial or systemic barriers that impede support from these sectors. \n\n" +
      "Describe alternative funding options for the same activities being proposed in the Concept Note, including an analysis of the barriers for the potential beneficiaries to access to finance and the constraints of public and private sources of funding. \n\n" +
      "Justify the rationale and level of concessionality of the GCF financial instrument(s) as well as how this will be passed on to the end-users and beneficiaries. Justify why this is the minimum required to make the investment viabl e and most efficient considering the incremental cost or risk premium of the Project/ Programme (refer to Decisions B.12/17; B.10/03; and B.09/04 for more details). The justification for grants and reimbursable grants is mandatory.",
    limit: 500,
  },
  {
    q: (
      <>
        Enter <i>Sustainability and replicability</i> of the project below:
      </>
    ),
    result: (
      <>
        Evaluation Report for <i>Sustainability and replicability</i> of the
        project Below:
      </>
    ),
    placeholder:
      "Please explain how the project/programme sustainability will be ensured in the long run and how this will be monitored, after the project/programme is implemented with support from the GCF and other sources. Please heed the guidance below: \n\n" +
      "Sustainability over the long term: The narrative should effectively explain how the project will achieve and maintain sustainability over the long term, detailing the mechanisms for monitoring post-implementation impacts with support from the Green Climate Fund (GCF) and other sources. It must clearly present an exit strategy that outlines how project activities and scope will continue after funding ceases. This includes specific plans for phasing out project activities while transitioning ownership and operational responsibilities to local partners, ensuring the maintenance of project assets, and continuous stakeholder engagement to guarantee ongoing support and success. The exit strategy should leave no ambiguity about the project's continued impact, clearly illustrating how the benefits will persist and expand beyond the project's formal conclusion. \n\n" +
      "Replicability: The narrative must clearly discuss the project’s potential for replication and scalability. It should demonstrate how the project can serve as a model for sustainable development, emphasizing its ability to be replicated beyond a single investment. The discussion should include specific details on how key structural elements of the project can be adapted and implemented in other regions or countries, thereby creating a legacy of sustainable development impact. This section should highlight the project's innovative aspects and how they contribute to broader sustainability goals, ensuring the narrative conveys a clear vision of the project’s potential to inspire and inform similar initiatives globally. \n\n",
    limit: 500,
  },
  // {
  //   q: (
  //     <>
  //       Enter <i>Needs of the Community</i> Below:
  //     </>
  //   ),
  //   result: (
  //     <>
  //       Evaluation Report for <i>Needs of the community</i> below:
  //     </>
  //   ),
  //   placeholder:
  //     "Explain how the project will meet the needs of the target communities. Be sure to heed the following guidance: \n\n" +
  //     "Community Involvement and Feedback Mechanisms: Your narrative should describe mechanisms for community involvement and feedback during project implementation. \n\n" +
  //     " Cultural and Social Considerations: Your narrative should account for local cultural, social, and gender considerations in the design and implementation of the project.",
  //   limit: 300
  // }
];
const sections = [
  "B_1",
  "B_1_1",
  "B_1_2",
  "B_1_3",
  "B_2",
  "B_2_1",
  "B_2_2",
  "B_3",
  "B_3_1",
  "B_3_2",
  "B_4",
  "C_1",
  "C_2",
  "C_3",
  // "C_3_1"
];
const sectionConfig = {
  B_1: {
    upload: true,
  },
  B_2_1: {
    upload: true,
  },
  C_1: {
    upload: true,
  },
};

const GradePage: React.FC<GradeFormProps> = ({}) => {
  const nav = useNavigate();
  const socketRef = useRef(null);
  const { type, step } = useParams();
  const [form] = useForm();
  const { setTokens } = useTokensContext();
  const { evaluateNarratives, setEvaluateNarratives } = useNarrativesContext();
  const {
    user: { getSingleProjectData, projectNameData },
    modelType: { getSelectedModelType },
  } = useStore(null);

  const projectName =
    (projectNameData && JSON.parse(projectNameData).project_name) ||
    localStorage.getItem("projectName");

  const [initialInput, setInitialInput] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [resultsData, setResultsData] = useState("");
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const [isAnswer, setIsAnswer] = useState(false);

  const email = localStorage.getItem("email");
  const isFormPage = type === "form";

  const results = isSubmitting
    ? ""
    : evaluateNarratives.find((el) => el.section === sections[+step - 1])?.res;

  const handleSubmit = useCallback(
    (values) => {
      if (!isValid) return;
      if (isFormPage) {
        setResultsData("");
        setIsSubmitting(true);
        const data: DataType = {
          section: sections[+step - 1],
          input: values?.q,
          project_name: projectName,
          email: email,
          model: getSelectedModelType ?? "GPT-4 (Turbo)",
        };

        if (base64Image) {
          data.input = {
            text: values?.q,
            image: base64Image,
          };
        }

        const socket = io(baseSocketUrl, {
          transports: ["websocket", "polling"],
        });

        socketRef.current = socket;
        console.log("Sending data ", data);
        socketRef?.current?.emit("conceptNoteEvaluator", data);

        socketRef.current?.on("answer", (answer) => {
          const isStopGeneration = localStorage.getItem("isStopGeneration");
          if (!isSubmitting && answer) {
            setIsSubmitting(true);
          }

          if (answer) {
            setIsAnswer(true);
          }

          if (isStopGeneration || answer === "#####") {
            socket.disconnect();
            setIsSubmitting(false);
            setIsAnswer(false);

            localStorage.removeItem("isStopGeneration");
            return;
          }

          form.setFieldValue("q", (form?.getFieldValue("q") ?? "") + answer);
          localStorage.setItem(
            "totalResultsEvaluate" + projectName,
            JSON.stringify({
              ...results,
              [`result${step}`]: (form?.getFieldValue("q") ?? "") + answer,
            }),
          );
        });
        socket.on("disconnect", () => {
          setIsSubmitting(false);
          setIsAnswer(false);

          localStorage.removeItem("isStopGeneration");
        });
        nav(constRoute?.gradeProject + "/results/" + step);
      } else {
        if (formQuestions.length < +step + 1) {
          nav(constRoute?.gcfCongratulation + "/evaluate");
          return;
        }
        setIsSubmitting(false);
        setIsAnswer(false);

        nav(constRoute?.gradeProject + "/form/" + (+step + 1));
      }
    },
    [type, step, base64Image, getSelectedModelType],
  );

  const handleGoBack = () => {
    socketRef?.current?.disconnect();
    setIsSubmitting(false);
    setIsAnswer(false);
    // if (isSubmitting) return;
    if (+step === 1 && type == "results") {
      nav(constRoute?.gradeProject + "/form/1");

      return;
    }

    if (+step < 2) {
      nav(constRoute.projectName + "/evaluate");
      return;
    }

    if (type === "results") {
      nav(constRoute?.gradeProject + "/form/" + +step);
    } else {
      nav(constRoute?.gradeProject + "/results/" + (+step - 1));
    }
  };

  const handleSaveQuit = () => {
    nav(constRoute?.existingProject);
  };

  const getProjectData = async () => {
    const data = {
      section: sections[+step - 1],
      project_name: projectName,
      functionality: "concept note evaluator",
    };

    setIsLoading(true);
    setBase64Image("");

    getSingleProjectData(data, null)
      .then((state) => {
        setInitialInput(state?.questions);
        if (isFormPage) {
          form.setFieldValue("q", state?.questions.input);

          if (state?.questions?.image) {
            setBase64Image(state?.questions?.image);
          }
        } else {
          if (!isSubmitting) {
            form.setFieldValue(`q`, state?.generated_narratives?.find(Boolean));
          }
        }

        setTokens(state["tokens_remaining/tokens_purchased"]);
        setEvaluateNarratives((prev) =>
          prev.map((el) => {
            if (el.section === sections[+step - 1]) {
              return {
                ...el,
                res: state.generated_narratives.map((el) => el).join("") || "",
              };
            } else {
              return el;
            }
          }),
        );
      })
      .finally(() => setIsLoading(false));
    if (+step < 1 || formQuestions.length + 1 <= +step) {
      nav(constRoute?.gcfCongratulation + "/evaluate");
    }
  };

  const handleStopGenerate = () => {
    if (socketRef) {
      socketRef.current.disconnect();
    }
    setIsAnswer(false);
    setIsSubmitting(false);
  };

  useEffect(() => {
    localStorage.removeItem("isStopGeneration");

    getProjectData();

    // return () => {
    //   localStorage.removeItem("isStopGeneration");
    // };
  }, [step, type]);

  useEffect(() => {
    form.resetFields();
  }, [type === "results"]);

  useEffect(() => {
    socketRef?.current?.disconnect();
    form.resetFields();
    setIsSubmitting(false);
    setIsAnswer(false);
    setResultsData(
      evaluateNarratives.find((el) => el.section === sections[+step - 1])
        ?.res || "",
    );
  }, [step]);

  useEffect(() => {
    return () => {
      socketRef?.current?.disconnect();
    };
  }, []);
  const section = sections[+step - 1];

  return (
    <div className={style.gradeContainer}>
      <GradeForm
        form={form}
        value={results}
        isSubmitting={isSubmitting}
        initialState={initialInput}
        handleSubmit={handleSubmit}
        isFormPage={isFormPage}
        Text={
          isFormPage
            ? formQuestions[+step - 1]?.q
            : formQuestions[+step - 1]?.result || results[`result${step}`] || ""
        }
        placeholder={formQuestions[+step - 1]?.placeholder}
        maxLength={formQuestions[+step - 1]?.limit}
        disabled={type === "results" || isLoading}
        section={section}
        step={step}
        setIsValid={setIsValid}
        isValid={isValid}
        allowUpload={sectionConfig[section] && sectionConfig[section].upload}
        base64Image={base64Image}
        setBase64Image={setBase64Image}
        handleStopGenerate={handleStopGenerate}
        isLoading={isLoading}
        isAnswer={isAnswer}
      />
      <CommonFooterButton
        customStyle={{ left: 0 }}
        isLoadingSubmit={false}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        handlegoback={handleGoBack}
        handleSaveAndQuit={handleSaveQuit}
        isSubmit={type === "results"}
        // handleQuickNext={constRoute?.contextAndBaselineResults}
        form={form}
        setIsSubmitting={setIsSubmitting}
        disableSubmit={!isValid || isLoading}
      />
    </div>
  );
};

export default memo(observer(GradePage));

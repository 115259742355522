import down from "@assets/images/down.svg";
import BaseLoader from "@assets/loaders/base-loader/BaseLoader";
import GoBack from "@components/common-components/go-back";
import { useStore } from "@stores/root-store";
import { validateMessages } from "@utils/json-data";
import { constRoute } from "@utils/route";
import { Col, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import style from "./style.module.scss";

const ProjectName = observer(() => {
  const navigate = useNavigate();
  const { type } = useParams();
  const [form] = useForm();
  const [inputValue, setInputValue] = useState(true);
  const [inputValueLength, setInputValueLength] = useState(false);
  const {
    user: { projectSave, loadingProjectSave },
  } = useStore(null);

  const onFormSubmit = async (value) => {
    value.projectName = value.projectName.trim();

    localStorage.setItem("projectName", value.projectName);
    localStorage.setItem("projectSector", value.project_sector);
    localStorage.removeItem("AllAnswers");
    const basePayload = {
      project_name: value?.projectName,
      project_size: value?.project_size,
      project_sector: value?.project_sector,
    };

    const payloadEvaluate = {
      ...basePayload,
      functionality: "concept note evaluator",
    };

    const payloadAdvisor = {
      ...basePayload,
      functionality: "project structuring",
    };

    const payloadDraft = {
      ...basePayload,
      functionality: "concept note",
    };

    const payloadTheoryOfChange = {
      ...basePayload,
      functionality: "theory of change",
    };

    const payloadRationalAdvisor = {
      ...basePayload,
      functionality: "climate rationale",
    };

    switch (type) {
      case "advisor":
        await projectSave(payloadAdvisor, navigate)
          .then((res) => {
            if (res?.message?.includes("project saved successfully")) {
              navigate(constRoute.advisorProject + "/form/1");
            }
          })
          .catch((err) => {});
        break;
      case "evaluate":
        await projectSave(payloadEvaluate, navigate)
          .then((res) => {
            if (res?.message?.includes("project saved successfully")) {
              navigate(constRoute.gradeProject + "/form/1");
            }
          })
          .catch((err) => {});
        break;
      case "draft":
        await projectSave(payloadDraft, navigate)
          .then((res) => {
            if (res?.message?.includes("project saved successfully")) {
              navigate(constRoute?.draftProject + "/form/1", {
                state: { projectName: value.projectName },
              });
            }
          })
          .catch((err) => {});
        break;
      case "theory-of-change":
        await projectSave(payloadTheoryOfChange, navigate)
          .then((res) => {
            if (res?.message?.includes("project saved successfully")) {
              navigate(constRoute?.theoryOfChangeProject + "/form/1", {
                state: { projectName: value.projectName },
              });
            }
          })
          .catch((err) => {});
        break;
      case "rational-advisor":
        await projectSave(payloadRationalAdvisor, navigate)
          .then((res) => {
            if (res?.message?.includes("project saved successfully")) {
              navigate(constRoute?.rationalAdvisorProject + "/form/1", {
                state: { projectName: value.projectName },
              });
            }
          })
          .catch((err) => {});
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    localStorage.removeItem("projectSector");
  }, []);

  const handleInputChange = (event) => {
    if (event.target.value.length > 100) {
      setInputValueLength(true);
    } else {
      setInputValueLength(false);
    }
    if (event.target.value.length > 0) {
      setInputValue(false);
    } else {
      setInputValue(true);
    }
  };

  return (
    <div>
      <div className={style.homePagePageContainer}>
        <div className={style.homePageContainerCol}>
          <div className={style.homePageContainer}>
            <h1>Tell us a bit about your project </h1>
            <p className={style.headerDesc}>
              This information will be plugged into pertinent sections of the
              workflows you will be using.
            </p>
            <Row justify="center" className={style.boxesContiner} gutter={20}>
              <Col span={24} style={{ padding: "0" }}>
                <Form
                  className={style.formData}
                  form={form}
                  style={{ padding: "0" }}
                  autoComplete="false"
                  onFinish={onFormSubmit}
                  validateMessages={validateMessages}
                  layout="vertical"
                >
                  <Form.Item
                    name={"projectName"}
                    label="Project Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter project name",
                      },
                    ]}
                  >
                    <Input
                      maxLength={115}
                      onChange={(e) => handleInputChange(e)}
                      type="text"
                      placeholder="Enter project name"
                      className={style.emailInput}
                    />
                  </Form.Item>
                  {
                    <p
                      style={{
                        display: "flex",
                        justifyContent: inputValueLength
                          ? "space-between"
                          : "end",
                        margin: 0,
                      }}
                    >
                      {inputValueLength && (
                        <span style={{ color: "red", marginTop: -12 }}>
                          Characters value exceeded
                        </span>
                      )}
                      <span style={{ marginTop: -12 }}>
                        Maximum 100 characters
                      </span>
                    </p>
                  }
                  <Form.Item
                    label={"Project Sector"}
                    name={"project_sector"}
                    rules={[
                      {
                        required: true,
                        type: "string",
                        message: `Please select the project sector`,
                      },
                    ]}
                  >
                    <Select
                      defaultValue="Select project sector"
                      placeholder="Select project sector"
                      style={{ width: "100%", textAlign: "start" }}
                      suffixIcon={<img src={down} alt={"down icon"} />}
                      options={[
                        {
                          value:
                            "Buildings, cities, industries, and appliances",
                          label:
                            "Buildings, cities, industries, and appliances",
                        },
                        {
                          value: "Ecosystems and ecosystem services",
                          label: "Ecosystems and ecosystem services",
                        },
                        {
                          value: "Energy generation and access",
                          label: "Energy generation and access",
                        },
                        {
                          value: "Forests and land use",
                          label: "Forests and land use",
                        },
                        {
                          value: "Health, food, and water security",
                          label: "Health, food, and water security",
                        },
                        {
                          value: "Infrastructure and built environment",
                          label: "Infrastructure and built environment",
                        },
                        {
                          value: "Livelihoods of people and communities",
                          label: "Livelihoods of people and communities",
                        },
                        {
                          value: "Transport",
                          label: "Transport",
                        },
                        {
                          value: "Other",
                          label: "Other",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label={"Project Size"}
                    name={"project_size"}
                    rules={[
                      {
                        required: true,
                        type: "string",
                        message: `Please select the project size`,
                      },
                    ]}
                  >
                    <Select
                      defaultValue="Select project size"
                      placeholder="Select project size"
                      style={{ width: "100%", textAlign: "start" }}
                      suffixIcon={<img src={down} alt={"down icon"} />}
                      options={[
                        {
                          value: "USD 10 million (micro)",
                          label: "USD 10 million (micro)",
                        },
                        {
                          value: "USD 10-50 million (small)",
                          label: "USD 10-50 million (small)",
                        },
                        {
                          value: "USD 50-250 (medium)",
                          label: "USD 50-250 (medium)",
                        },
                        {
                          value: "> USD 250 million (large)",
                          label: "> USD 250 million (large)",
                        },
                      ]}
                    />
                  </Form.Item>
                  <div className={style.nextButtonDiv}>
                    <button
                      disabled={
                        loadingProjectSave || inputValue || inputValueLength
                      }
                      style={{ position: "relative" }}
                      className={style.nextButton}
                    >
                      Next
                      {loadingProjectSave && <BaseLoader />}
                    </button>
                  </div>
                  {loadingProjectSave && (
                    <p style={{ textAlign: "start" }}>
                      Please wait while your project is being created
                    </p>
                  )}
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className={style.btnDiv}>
        <div className={style.twoBtnDiv}>
          <div className={style.goBackBtnDiv}>
            <GoBack
              onClick={
                type === "advisor"
                  ? constRoute.projectStructureWelcome
                  : type === "theory-of-change"
                    ? constRoute.theoryOfChangeWelcome
                    : type === "rational-advisor"
                      ? constRoute.rationalAdvisorWelcome
                      : constRoute?.selectFunction
              }
              className={style.goBtn}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ProjectName;

// Helper function to get environment variables at runtime
const getEnvVariable = (key, stripPath = false, defaultValue = "") => {
  // Check process.env (for build-time variables)
  console.log("get env variable called ", key);
  const envValue = process.env[key];

  // Fallback to window.RUNTIME_ENV (for runtime variables) if process.env is empty
  // @ts-ignore
  const finalValue = envValue || window.RUNTIME_ENV?.[key] || defaultValue;
  console.log("final value is ", finalValue);
  if (stripPath) {
    try {
      const url = new URL(finalValue);
      // Reconstruct the URL without the path, query, or fragment
      return `${url.protocol}//${url.host}`;
    } catch (error) {
      // If it's not a valid URL, return the original value
      console.warn(`getEnvVariable: Invalid URL: ${finalValue}`);
      return finalValue;
    }
  } else {
    return finalValue;
  }
};

// Define the environment variables with fallback logic
export const baseUrl = getEnvVariable("REACT_APP_BASE_URL"); // Live for live server
export const baseSocketUrl = getEnvVariable("REACT_APP_BASE_URL", true);
export const baseUrlMasterAdmin = getEnvVariable("REACT_APP_ADMIN_BASE_URL");

export const baseUrlFiles = "baseUrl";

export const updateThemeUrl = `${baseUrl}updateDefaultTheme`;

const localFrontendUrl = getEnvVariable("REACT_APP_FRONTEND_URL");
const vercelEnv = getEnvVariable("REACT_APP_VERCEL_ENV");
let frontendDomain;
if (localFrontendUrl) {
  frontendDomain = localFrontendUrl;
} else {
  frontendDomain =
    vercelEnv === "production"
      ? getEnvVariable("REACT_APP_VERCEL_PROJECT_PRODUCTION_URL")
      : "cfcstaging.vercel.app";
}

export const frontendUrl = `http${process.env.NODE_ENV === "development" ? "" : "s"}://${frontendDomain}`;

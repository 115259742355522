import { FC, memo, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { useTokensContext } from "@components/layout/main-layout/private-layout";
import PlaygroundForm from "@components/pages/project/Playground/PlaygroundForm";
import { ChatRole, IChat } from "@components/pages/project/Playground/types";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { useForm } from "antd/es/form/Form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { baseSocketUrl } from "@api/const";

interface AdvisorFormProps {}
const formQuestions = [
  {
    q: <span>Hello! How Can I Help You Today?</span>,
    result: <span>Your Results Below:</span>,
    limit: 1000,
    placeholder:
      "Here are a few things to know when utilizing this tool:\n" +
      "\n" +
      "• Enter any query or task into the box located at the bottom of this page.\n" +
      "• The Playground will remember your queries/tasks, enabling you to ask follow-up questions as needed.\n" +
      "• Be aware that once you navigate from this page, your session will be cleared from memory, so make sure to save any information outputs.\n" +
      "• For assistance on crafting effective prompts, refer to the prompt writing tips guide situated at the top right of this page.",
  },
];

const convertFileToBase64 = (file): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const data = reader.result.toString();
      resolve(data.split(",")[1]);
    };
    reader.onerror = (error) => reject(error);
  });
};

const resizeImageAsync = (file, maxWidth, maxHeight): Promise<string> => {
  return new Promise((resolve, reject) => {
    resizeImage(file, maxWidth, maxHeight, (blob) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        const base64Image = e.target.result;
        resolve(base64Image.toString());
      };

      reader.readAsDataURL(blob);
    });
  });
};

function resizeImage(file, maxWidth, maxHeight, callback) {
  const img = new Image();
  const reader = new FileReader();

  reader.onload = function (event) {
    img.src = event.target.result as string;
  };

  img.onload = function () {
    let width = img.width;
    let height = img.height;

    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }

    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    canvas.toBlob(function (blob) {
      callback(blob);
    }, "image/jpeg");
  };

  reader.readAsDataURL(file);
}

const Playground: FC<AdvisorFormProps> = () => {
  const nav = useNavigate();
  const socketRef = useRef(null);
  const { type, step } = useParams();
  const { pathname } = useLocation();
  const [form] = useForm();
  const [question, setQuestion] = useState("");
  const [url, setUrl] = useState("");
  const [isUrlBoxOpen, setIsUrlBoxOpen] = useState(false);
  const [chat, setChat] = useState<IChat[]>([]);
  const [research, setResearch] = useState("");
  const [scoring, setScoring] = useState("");
  const [country, setCountry] = useState("");
  const [sector, setSector] = useState("");
  const [annex, setAnnex] = useState("");
  const [climateRationalePrompt, setClimateRationalePrompt] = useState("");
  const [documentsMap, setDocumentsMap] = useState({});
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<File | null>(null);
  const { setTokens } = useTokensContext();

  const projectName = localStorage.getItem("projectName");
  const results = JSON.parse(
    localStorage.getItem("totalResultsAdvisor" + projectName),
  );

  const {
    user: { handleGetGuidePdf, getSingleProjectData },
    modelType: { getSelectedModelType },
  } = useStore(null);
  const [initialInput, setInitialInput] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [questions, setQuestions] = useState("");
  const [resultsData, setResultsData] = useState(results || "");
  const [input, setInput] = useState("");

  const getProjectData = async () => {
    const data = JSON.stringify({ functionality: "playground" });

    await getSingleProjectData(data, null).then((state) => {
      if (state) {
        setTokens(state["tokens_remaining/tokens_purchased"]);
      }
    });
  };

  const isFormPage = type === "form";
  const handleSubmit = async (
    values,
    regenConfig: {
      isRegen?: boolean;
      lastMessage?: any;
      configOnlyMessage?: boolean;
    } = {},
  ) => {
    const inputVal = regenConfig.isRegen === true ? values.q : input;
    setQuestion(form?.getFieldValue("q"));
    setResultsData("");
    setIsSubmitting(true);

    let selectedDocumentBase64 = "";
    if (selectedDocument) {
      selectedDocumentBase64 = await convertFileToBase64(selectedDocument);
    }

    if (!inputVal && (sector === "" || country === "")) {
      setIsSubmitting(false);
      return;
    }

    setChat((prev) =>
      prev.map((el) => {
        return { ...el, isLastChatMessage: false };
      }),
    );

    let message = {
      id: chat[chat.length - 1]?.id ? chat[chat.length - 1]?.id + 1 : 1,
      role: ChatRole.USER,
      message: inputVal,
      image: selectedFile,
      url,
      containsFile: selectedFile !== null || selectedDocument !== null,
      isLastChatMessage: false,
    };

    const previousMessageId = chat[chat.length - 1]?.id;
    const messageId = message.id;
    /*
      If previous message, doesn't have a document, and now we have selectedDocument defined
      we should create a mapping
    */
    if (documentsMap[previousMessageId] === undefined && selectedDocument) {
      documentsMap[messageId] = {
        name: selectedDocument.name,
        type: selectedDocument.type,
        data: selectedDocumentBase64,
      };

      setDocumentsMap({ ...documentsMap });
    }

    const answer = {
      id: chat[chat.length - 1]?.id ? chat[chat.length - 1]?.id + 2 : 2,
      role: ChatRole.CHAT,
      message: "",
      isLastChatMessage: true,
    };

    const email = localStorage.getItem("email");

    setInitialInput(values?.q);

    const data: any = {
      conversation:
        chat.length === 0
          ? [
              {
                role: "user",
                content: [
                  {
                    type: "text",
                    text: inputVal,
                  },
                ],
              },
            ]
          : [
              ...chat.map((el) => {
                const res = {
                  role: el.role === ChatRole.CHAT ? "assistant" : "user",
                  content: [
                    {
                      type: "text",
                      text: el.message,
                    },
                  ],
                };

                if (el?.image_url) {
                  res.content.push({
                    type: "image_url",
                    // @ts-ignore
                    image_url: { url: el?.image_url },
                  });
                }
                return res;
              }),
              {
                role: "user",
                content: [
                  {
                    type: "text",
                    text: inputVal,
                  },
                ],
              },
            ],
      email,
      model: getSelectedModelType ?? "GPT-4 (Turbo)",
    };

    if (climateRationalePrompt !== "") {
      data.climateRationalePrompt = climateRationalePrompt;
    }

    if (country !== "") {
      data.country = country;
    }

    if (sector !== "") {
      data.sector = sector;
    }

    if (annex !== "") {
      data.annex = annex;
    }

    if (url !== "") {
      data.url = url;
    }

    // if (scoring !== "") {
    //   data.scoring = scoring;
    // }
    //
    // if (research !== "") {
    //   data.research = research;
    // }
    //
    // if (sector !== "") {
    //   data.sector = sector;
    // }
    //
    // if (annex !== "") {
    //   data.annex = annex;
    // }
    //
    // if (climateRationalePrompt !== "") {
    //   data.climateRationalePrompt = climateRationalePrompt;
    // }
    //
    // if (country !== "") {
    //   data.country = country;
    // }
    //
    // if (!inputVal && country !== '' && sector !== '') {
    //   data.conversation = []
    //   message.message = ''
    // }

    if (country !== "" && sector !== "" && climateRationalePrompt !== "") {
      data.conversation = [];
      data.input = inputVal;
    }

    const socket = io(baseSocketUrl, {
      transports: ["websocket", "polling"],
    });

    if (selectedFile) {
      const base64Image = await resizeImageAsync(selectedFile, 1024, 768);

      data.conversation[data.conversation.length - 1].content.push({
        type: "image_url",
        // @ts-ignore
        image_url: { url: base64Image },
      });

      message["image_url"] = base64Image;
    }

    if (selectedDocument) {
      data.file_name = selectedDocument.name;
      data.pdf_data = selectedDocumentBase64;
    } else if (Object.keys(documentsMap).length > 0) {
      const lastMessageWithDocumentId = Object.keys(documentsMap).sort().pop();
      data.file_name = documentsMap[lastMessageWithDocumentId].name;
      data.pdf_data = documentsMap[lastMessageWithDocumentId].data;
    }

    if (
      regenConfig.isRegen === true &&
      regenConfig.configOnlyMessage !== true
    ) {
      message = regenConfig.lastMessage;

      if (documentsMap[message.id]) {
        data.file_name = documentsMap[message.id].name;
        data.pdf_data = documentsMap[message.id].data;
      }
    }

    setChat((prev) => [...prev, message, answer]);
    socket.emit("playground", data);

    socketRef.current = socket;
    setInput("");
    setIsUrlBoxOpen(false);
    setSelectedFile(null);
    setSelectedDocument(null);
    setResultsData("");

    socket.on("answer", (answer) => {
      const isStopGeneration = localStorage.getItem("isStopGeneration");
      if (!isSubmitting && answer) {
        setIsSubmitting(true);
      }

      if (isStopGeneration || answer === "#####") {
        socket.disconnect();
        localStorage.removeItem("isStopGeneration");
        setIsSubmitting(false);
        return;
      }

      setResultsData(answer);
      setChat((prev) =>
        prev.map((el) => {
          if (el.id === prev[prev.length - 1].id) {
            return {
              id: prev[prev.length - 1].id + 1,
              role: ChatRole.CHAT,
              message: prev[prev.length - 1].message + answer,
              isLastChatMessage: true,
            };
          } else {
            return el;
          }
        }),
      );
    });

    socket.on("disconnect", () => {
      setIsSubmitting(false);
      getProjectData();
    });
  };

  useEffect(() => {
    getProjectData();
  }, []);

  const stopGeneration = async () => {
    if (socketRef.current) {
      await socketRef.current.disconnect();
      await setIsSubmitting(false);
    }

    setChat((prev) =>
      prev.map((message, i) => {
        if (i !== chat.length - 1) {
          return message;
        } else {
          if (message.role === ChatRole.CHAT && message.message === "") {
            message.message = "__INTERNAL_STOPPED__";
          }

          return message;
        }
      }),
    );
  };

  const handleRegenerate = () => {
    const lastMessage = { ...chat[chat.length - 2] };
    const question = lastMessage.message;
    setChat(chat.slice(0, -2));
    setInput(question);
    form.setFieldValue("q", "");

    setQuestions(question);
    const values = {
      q: question,
    };

    handleSubmit(values, { isRegen: true, lastMessage });
  };

  const onDownloadPdf = async () => {
    await handleGetGuidePdf().then((blob) => {
      const url = window.URL.createObjectURL(blob);

      const newTab = window.open();
      newTab.document.write(
        '<iframe width="100%" height="100%" src="' +
          url +
          '"frameborder="0"></iframe>',
      );
      newTab.document.body.style.margin = "0";

      window.URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    return () => {
      socketRef?.current?.disconnect();
    };
  }, []);

  if (isLoading) {
    return <></>;
  }

  return (
    <div style={{ padding: "30px 52px 12px" }}>
      <PlaygroundForm
        form={form}
        value={resultsData}
        isSubmitting={isSubmitting}
        initialState={initialInput}
        handleSubmit={handleSubmit}
        handleRegenerate={handleRegenerate}
        isFormPage={isFormPage}
        question={resultsData}
        setInput={(val: string) => setInput(val)}
        input={input}
        setChat={setChat}
        chat={chat}
        url={url}
        setUrl={setUrl}
        isUrlBoxOpen={isUrlBoxOpen}
        setIsUrlBoxOpen={setIsUrlBoxOpen}
        research={research}
        setResearch={setResearch}
        scoring={scoring}
        setScoring={setScoring}
        sector={sector}
        setSector={setSector}
        annex={annex}
        setAnnex={setAnnex}
        country={country}
        setCountry={setCountry}
        climateRationalePrompt={climateRationalePrompt}
        setClimateRationalePrompt={setClimateRationalePrompt}
        Text={
          isFormPage
            ? formQuestions[+step - 1]?.q
            : formQuestions[+step - 1]?.result || results[`result${step}`] || ""
        }
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        selectedDocument={selectedDocument}
        setSelectedDocument={setSelectedDocument}
        documentsMap={documentsMap}
        placeholder={formQuestions[+step - 1]?.placeholder}
        maxLength={formQuestions[+step - 1]?.limit}
        disabled={type === "results"}
        onDownloadPdf={onDownloadPdf}
        stopGeneration={() => stopGeneration()}
      />
    </div>
  );
};

export default memo(observer(Playground));

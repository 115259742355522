import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStore } from "@stores/root-store";
import { ContentToExport } from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/gcf-congratulation/components/ContentToExport";

const ProjectResults = () => {
  const { search } = useLocation();

  const [sections, setSections] = useState(null);

  const urlParams = new URLSearchParams(search);

  const projectName = urlParams.get("project-name");
  const projectType = urlParams.get("project-type");

  const {
    user: {
      loadGetCongratulationResults,
      loadGetDraftResults,
      loadGetAdvisorResults,
      loadGetTheoryOfChangeResults,
    },
  } = useStore(null);

  const handleGetProjectResults = async () => {
    const result =
      projectType === "advisor"
        ? await loadGetAdvisorResults({ project_name: projectName })
        : projectType === "draft"
          ? await loadGetDraftResults({ project_name: projectName })
          : projectType === "theory-of-change"
            ? await loadGetTheoryOfChangeResults({ project_name: projectName })
            : await loadGetCongratulationResults({ project_name: projectName });

    setSections(result);
  };

  const sectionDataHandler = (item) => {
    return sections[item]?.length ? sections[item] : "results...";
  };

  useEffect(() => {
    handleGetProjectResults();
  }, []);

  return (
    <div
      style={{
        width: "80%",
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      {sections && (
        <ContentToExport
          sections={Object.keys(sections)}
          sectionDataHandler={sectionDataHandler}
        />
      )}
    </div>
  );
};

export default ProjectResults;

import { BaseApi } from "@api/baseApi";
import {
  IContactSalesSend,
  IGetPromptRequest,
  IUpdatePromptRequest,
} from "../../types/admin-panel/interfaces";
import axios from "axios";
import { baseUrl, baseUrlMasterAdmin } from "@api/const";
import { getMasterAuthorizationHeader } from "@api/common-utils";

export class AdminPanelApi extends BaseApi {
  static async sendContactSales(data: IContactSalesSend): Promise<any> {
    const cancelToken = axios.CancelToken.source().token;
    try {
      const response = await axios.post(`${baseUrl}contactSales`, data, {
        headers: { Authorization: getMasterAuthorizationHeader() },
        cancelToken: cancelToken,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  static async getPrompt(data: IGetPromptRequest): Promise<any> {
    try {
      const cancelToken = axios.CancelToken.source().token;
      const response = await axios.post(
        `${baseUrlMasterAdmin}getPrompt`,
        data,
        {
          headers: { Authorization: getMasterAuthorizationHeader() },
          cancelToken: cancelToken,
        },
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }

  static async updatePrompt(data: IUpdatePromptRequest): Promise<any> {
    try {
      const cancelToken = axios.CancelToken.source().token;
      const response = await axios.post(
        `${baseUrlMasterAdmin}updatePrompt`,
        data,
        {
          headers: { Authorization: getMasterAuthorizationHeader() },
          cancelToken: cancelToken,
        },
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  }
}

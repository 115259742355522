import React, { FC, useEffect } from "react";
import { Modal } from "antd";
import { createPortal } from "react-dom";
import "./style.scss";
interface ModalSaveProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
}
export const ModalSave: FC<ModalSaveProps> = ({
  isOpen,
  handleClose,
  handleSubmit,
}) => {
  return (
    <>
      {createPortal(
        <Modal
          title={[<div>Save & Quit</div>]}
          centered
          open={isOpen}
          onOk={handleSubmit}
          onCancel={handleClose}
          cancelText={"No, cancel"}
          okText={"Yes, save & quit"}
          destroyOnClose={true}
          className={"saveQuitModal"}
        >
          <h2 style={{ margin: "0" }}>Are you sure you want to save & quit?</h2>
          <p>
            {" "}
            If you're on the input screen and choose to save and quit, only your
            entered information will be saved.
          </p>
          <p>
            If you're on the output screen and want to preserve the generated
            output make sure to advance to the next section before selecting
            ‘save & quit.’
          </p>
        </Modal>,
        document.getElementById("root"),
      )}
    </>
  );
};

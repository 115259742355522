import { createContext, memo, useContext, useState } from "react";
import Routing from "../../../../router-service";
import { observer } from "mobx-react";
import style from "../../style.module.scss";
import Header from "./header";
import { Layout } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@utils/hooks/useTheme";
import UpgradePlanBar from "./upgrade-plan-bar";
import ProjectHeader from "@components/layout/main-layout/private-layout/project-header";
import { UpgradePlanModalProvider } from "@components/ModalContext/ModalContext";
import UpgradePlanModal from "@commonComponents/upgrate-plan-modal/UpgradePlanModal";
import SessionWarningModal from "@components/layout/main-layout/private-layout/session-expiration-modal";

const TokenContext = createContext(null);
const NarrativesContext = createContext(null);

const PrivateLayout = observer(() => {
  const [showPremiumBar, setShowPremiumBar] = useState(true);
  const [tokens, setTokens] = useState("");
  const [evaluateNarratives, setEvaluateNarratives] = useState([
    { section: "B_1", res: "" },
    { section: "B_2", res: "" },
    { section: "B_3", res: "" },
    { section: "B_4", res: "" },
    { section: "C_2", res: "" },
    { section: "C_3", res: "" },
  ]);
  const [advisorNarratives, setAdvisorNarratives] = useState([
    { section: "pis", res: "" },
    { section: "fsa", res: "" },
    { section: "fea", res: "" },
    { section: "rc", res: "" },
    { section: "it", res: "" },
    { section: "omm", res: "" },
  ]);
  const [tocNarratives, setTocNarratives] = useState([
    { section: "GS", res: "" },
    { section: "SA", res: "" },
    { section: "OP", res: "" },
    { section: "PI", res: "" },
    { section: "AB", res: "" },
    { section: "NLF", res: "" },
  ]);

  const [rationalAdvisor, setRationalAdvisor] = useState([
    { section: "i_25", res: "" },
    { section: "r_50", res: "" },
    { section: "a_75", res: "" },
    { section: "me_100", res: "" },
  ]);

  const [draftNarratives, setDraftNarratives] = useState([
    { section: "B_4_64", res: "" },
    { section: "C_3_90", res: "" },
    { section: "B_3_48", res: "" },
    { section: "B_3_40", res: "" },
    { section: "B_1_0", res: "" },
    { section: "C_2_72", res: "" },
    { section: "B_2_8", res: "" },
    { section: "B_3_56", res: "" },
    { section: "B_3_32", res: "" },
    { section: "B_3_24", res: "" },
    { section: "B_3_16", res: "" },
  ]);

  const { Sider, Content } = Layout;
  const isProjectPages =
    useLocation().pathname.includes("/project/") ||
    useLocation().pathname.includes("/playground/");
  const theme = useTheme();
  const navigate = useNavigate();
  const router = useLocation();
  const isUpgradePlanBarShow =
    router.pathname.includes("/project/") || router.pathname.length === 1;
  const handleCloseBar = () => {
    setShowPremiumBar(false);
  };

  // const {
  //   user: { loadUserInfo, getUserInfo },
  // } = useStore(null);

  // useEffect(() => {
  //   if (getUserInfo == null && window.location.pathname !== constRoute.login) {
  //     loadUserInfo(navigate);
  //   }
  // }, []);

  return (
    <div className={theme}>
      <TokenContext.Provider value={{ tokens, setTokens }}>
        <NarrativesContext.Provider
          value={{
            evaluateNarratives,
            setEvaluateNarratives,
            tocNarratives,
            setTocNarratives,
            advisorNarratives,
            setAdvisorNarratives,
            draftNarratives,
            setDraftNarratives,
            rationalAdvisor,
            setRationalAdvisor,
          }}
        >
          <UpgradePlanModalProvider>
            <Layout className={style.layoutSetting}>
              <SessionWarningModal />
              <UpgradePlanModal />
              {!useLocation().pathname.includes("/set-new-password") && (
                <Header />
              )}
              {showPremiumBar &&
                !isUpgradePlanBarShow &&
                !useLocation().pathname.includes("/playground/") &&
                !useLocation().pathname.includes("/set-new-password") && (
                  <UpgradePlanBar onClose={handleCloseBar} />
                )}
              {isProjectPages && <ProjectHeader />}
              <Content className={style.routingPagesContainer}>
                <Routing />
              </Content>
            </Layout>
          </UpgradePlanModalProvider>
        </NarrativesContext.Provider>
      </TokenContext.Provider>
    </div>
  );
});

export function useTokensContext() {
  return useContext(TokenContext);
}

export function useNarrativesContext() {
  return useContext(NarrativesContext);
}

export default memo(PrivateLayout);

import { BaseApi } from "@api/baseApi";
import axios from "axios";
import { getMasterAuthorizationHeader } from "@api/common-utils";
import { baseUrlMasterAdmin } from "@api/const";

interface ILoginData {
  email: string;
  password: string;
}

const baseUrl: string = baseUrlMasterAdmin;

class MasterAdmin extends BaseApi {
  login = async (data: ILoginData) => {
    try {
      const response = await axios.post(`${baseUrl}signin`, data, {
        headers: {
          "Content-Type": "application/json",
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  getCurrentUserDetails = async () => {
    try {
      const response = await axios.get(`${baseUrl}getCurrent`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getMasterAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  receiveAllUsers = async () => {
    try {
      const response = await axios.get(`${baseUrl}getUsers`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getMasterAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  deleteUserByEmail = async (email: string) => {
    try {
      const response = await axios.post(
        `${baseUrl}deleteUser`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getMasterAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  updateUserPlan = async ({
    email,
    new_plan,
  }: {
    email: string;
    new_plan: string;
  }) => {
    try {
      const response = await axios.post(
        `${baseUrl}upgradeUser`,
        { email, new_plan },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getMasterAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  downgradeUserPlan = async ({
    email,
    new_plan,
  }: {
    email: string;
    new_plan: string;
  }) => {
    try {
      const response = await axios.post(
        `${baseUrl}downgradeUser`,
        { email, new_plan },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getMasterAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  getUserData = async ({ email }: { email: string }) => {
    try {
      const response = await axios.post(
        `${baseUrl}getUserData`,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getMasterAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  addUsersToAccount = async ({
    email,
    subaccount_email,
  }: {
    email: string;
    subaccount_email: string;
  }) => {
    try {
      const response = await axios.post(
        `${baseUrl}addSubaccounts`,
        { email, subaccount_email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getMasterAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  getDashboardData = async () => {
    try {
      const response = await axios.get(`${baseUrl}getDashboardData`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getMasterAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  getFiles = async (data: any) => {
    try {
      const response = await axios.post(`${baseUrl}getFiles`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getMasterAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  getFileInfo = async (data: any) => {
    try {
      const response = await axios.post(`${baseUrl}getFileContent`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getMasterAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  removeFile = async (data: any) => {
    try {
      const response = await axios.post(`${baseUrl}deleteFile`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: getMasterAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  createFile = async (data: any) => {
    try {
      const response = await axios.post(`${baseUrl}saveFiles`, data, {
        headers: {
          Authorization: getMasterAuthorizationHeader(),
        },
        cancelToken: this.cancelToken,
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  };

  purchaseTokens = async ({
    email,
    new_purchased_tokens,
  }: {
    email: string;
    new_purchased_tokens: string;
  }) => {
    try {
      const response = await axios.post(
        `${baseUrl}purchaseTokens`,
        { email, new_purchased_tokens },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: getMasterAuthorizationHeader(),
          },
          cancelToken: this.cancelToken,
        },
      );

      return response.data;
    } catch (e) {
      throw e;
    }
  };
}

export default MasterAdmin;

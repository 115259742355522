import { catchError } from "@utils/common-functions";
import { flow, toJS } from "mobx";
import { types } from "mobx-state-tree";

export const modelType = types
  .model({
    selectedModelType: types.optional(types.maybeNull(types.string), null),
  })
  .views((self) => ({
    get getSelectedModelType() {
      return toJS(self.selectedModelType);
    },
  }))
  .actions((self) => {
    const changeModelType = flow(function* (data) {
      let response = null;
      try {
        self.selectedModelType = data;
        response = data;
        yield data;
      } catch (error) {
        catchError(error, "changeModelType");
      } finally {
        return response;
      }
    });

    return {
      changeModelType,
    };
  });

export function initModelType() {
  return modelType.create({});
}

import { observer } from "mobx-react-lite";
import PublicLayout from "./main-layout/public-layout";
import PrivateLayout from "./main-layout/private-layout";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { useStore } from "@stores/root-store";
import AdminLayout from "@components/layout/admin-layout/AdminLayout";
import SuperAdminLayout from "@components/layout/superadmin-layout/SuperAdminLayout";
import SuperAdminLogin from "@components/pages/SuperAdmin/SuperAdminLogin";

const DefaultLayout = observer(() => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isToken, setIsToken] = useState(localStorage.getItem("token"));
  const [isMasterToken, setIsMasterToken] = useState(
    localStorage.getItem("master_token"),
  );
  const {
    user: { loadUserInfo, getCurrentUserData, setUserType },
  } = useStore(null);

  const isResetPasswordPage = location.pathname?.includes(
    constRoute.resetPasswordSuccessfully,
  );

  useEffect(() => {
    let plan = localStorage.getItem("plan");

    if (plan === "undefined") {
      plan = "premium";
    }

    setUserType(plan || "premium");
  }, [setUserType]);

  useEffect(() => {
    if (localStorage.getItem("token")?.length > 0) {
      setIsToken(localStorage.getItem("token"));
      // navigate(constRoute?.home)
    } else {
      if (
        !location.pathname?.includes(constRoute.setting) &&
        !location.pathname?.includes(constRoute.TermOfUse) &&
        !location.pathname?.includes(constRoute.setNewPassword) &&
        !location.pathname?.includes(constRoute.admin)
      ) {
        navigate(constRoute?.dashboard);
        localStorage.removeItem("token");
        setIsToken("");
      }
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    if (localStorage.getItem("master_token")?.length > 0) {
      setIsMasterToken(localStorage.getItem("master_token"));
    } else {
      if (location.pathname?.includes(constRoute.masteradminLogin)) {
        navigate(constRoute.masteradminLogin);
        setIsMasterToken("");
        localStorage.removeItem("master_token");
      }
    }
  }, [localStorage.getItem("master_token")]);

  const handleLoadUserInfoDetal = async () => {
    await loadUserInfo(navigate);
  };

  useEffect(() => {
    if (
      !location.pathname?.includes(constRoute.login) &&
      location.pathname !== "/" &&
      !location.pathname?.includes(constRoute.signup) &&
      !location.pathname?.includes(constRoute.verifyEmail) &&
      !location.pathname?.includes(constRoute.forgetPassword) &&
      !location.pathname?.includes(constRoute.TermOfUse) &&
      !location.pathname?.includes(constRoute.setting) &&
      !location.pathname?.includes(constRoute.checkEmail) &&
      !location.pathname?.includes(constRoute.setNewPassword) &&
      !location.pathname?.includes(constRoute.admin) &&
      !location.pathname?.includes(constRoute.masteradminLogin) &&
      !location.pathname?.includes(constRoute.resetPasswordSuccessfully)
    )
      handleLoadUserInfoDetal();
  }, [location?.pathname]);

  return (
    (location.pathname?.includes(constRoute.masteradminLogin) &&
      !isMasterToken && <SuperAdminLogin />) ||
    (location.pathname?.includes(constRoute.masteradminLogin) &&
      isMasterToken && <SuperAdminLayout />) ||
    ((!isToken || isResetPasswordPage) &&
      !location.pathname?.includes(constRoute.masteradminLogin) && (
        <PublicLayout />
      )) ||
    (isToken && location.pathname?.includes(constRoute.admin) ? (
      <AdminLayout />
    ) : (
      isToken && <PrivateLayout />
    ))
  );
});

export default DefaultLayout;

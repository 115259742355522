import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { constRoute } from "@utils/route";
import { Modal } from "antd";
import style from "./style.module.scss";
import welcomeCharsImage from "@assets/images/welcome_modal_chars.png";

// import { userApi } from "@api";

interface modalProps {
  open?: any;
  setOpenModal?: any;
}

const SignupTrialModal: React.FC<modalProps> = ({ open, setOpenModal }) => {
  const navigate = useNavigate();
  const handleClose = async () => {
    localStorage.removeItem("showInfoModal");
    setOpenModal(false);
  };
  const handleTools = async () => {
    localStorage.removeItem("showInfoModal");
    navigate(constRoute.selectDonor);
  };
  const handlePlayground = async () => {
    localStorage.removeItem("showInfoModal");
    navigate("/playground/form/1");
  };

  return (
    <Modal
      width={733}
      title=""
      footer={null}
      open={open}
      centered
      onCancel={handleClose}
      styles={{
        content: {
          padding: 0,
        },
      }}
    >
      <div className={style.top}>
        <img src={welcomeCharsImage} style={{ width: "241px" }} />
      </div>
      <div className={style.contentWrapper}>
        <article>
          <p className={style.title}>
            Welcome to the world's first AI software
            <br />
            designed for climate finance operations!
          </p>
          <p>
            This software helps you to secure funding for your climate projects
            by leveraging the power of AI to supercharge your workflows. The two
            work areas of the tool kit are:
          </p>
          <ul>
            <li>
              Playground: A workspace providing ample room for complex projects
              and expansive thinking that commercial AI chat tools simply can't
              handle.
            </li>
            <li>
              Single function tools: Specialized tools, aligned with funders’
              criteria, that take the headache out of complex tasks like
              developing a Climate Rationale.
            </li>
          </ul>
          <p>
            <b>Ready to get started?</b>
            <br />
            Start your adventure with Playground first. It’s the perfect place
            to brainstorm, develop project ideas, research and much more. When
            you're ready to take things further, our single function tools will
            get you to the finance finish line.
          </p>
          <p className={style.contentWrapperSignature}>
            <b>Let’s make an impact together!</b> <br />- The Janus Team
          </p>
        </article>
      </div>
      <div className={style.footer}>
        <div className={style.footerContent}>
          <button onClick={handlePlayground} className={style.gotItBtn}>
            Go to Playground
          </button>
          <button
            onClick={handleTools}
            className={[style.gotItBtn, style.gotItBtnShallow].join(" ")}
          >
            Go to Single Function Tools
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default memo(SignupTrialModal);

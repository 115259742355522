import { notification } from "@utils/notifications";
import { resetStore } from "@stores/root-store";
// import xls from "xlsx";
import * as xls from "xlsx";
import * as FileSaver from "file-saver";

import { constRoute } from "@utils/route";
// import { saveAs } from "file-saver";
import * as _ from "lodash";

export const addDebounce = (fn, delay) => {
  let timer;
  return (() => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(), delay);
  })();
};

export const catchError = (
  error,
  at = "Mention Store Action Name To Track Error At:",
) => {
  const { status, data } = error.response;
  if (status === 401) {
    notification.error(
      data?.error?.message || data?.error || data?.message || "Error 401",
    );
  }

  if (status === 400) {
    notification.error(data?.error || data?.message);
  }

  data?.errors?.length > 0 &&
    data.errors?.forEach((item) => {
      notification.error(item?.msg);
    });
};

export const onLogOutClearAll = (navigate = null) => {
  resetStore();
  localStorage.removeItem("AllAnswers");
  localStorage.removeItem("sessionActivity");
  localStorage.removeItem("token");
  localStorage.removeItem("theme");
  localStorage.removeItem("email");
  localStorage.removeItem("userData");
  if (navigate !== null) {
    window.location.href = constRoute.hash + navigate;
  }
};
export const truncate = function (
  str: string,
  length: number = 16,
  ending: string = "...",
) {
  if (str?.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const sortCol = (a, b, dataIndex) => {
  if (a[dataIndex]?.length > 0 && b[dataIndex]?.length > 0) {
    return a[dataIndex].length - b[dataIndex].length;
  } else {
    return null;
  }
};
export const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  const view = new Uint8Array(buf); //create uint8array as viewer
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
  return buf;
};

export const fitToColumn = (arrayOfArray) => {
  // get maximum character of each column
  return arrayOfArray[0].map((a, i) => ({
    wch: Math.max(
      ...arrayOfArray.map((a2) => (a2[i] ? a2[i].toString().length : 0)),
    ),
  }));
};
export const generateExcel = (
  file_name,
  title,
  subject,
  sheet_name,
  author,
  headings,
  columns,
  file_data,
  footers = [""],
) => {
  const arr = [];
  if (file_data?.length) {
    file_data.map((item) => {
      const obj = {};
      const keys = Object.keys(item);
      keys.map((key) => {
        if (item[key]?.toString()) obj[key] = item[key];
        else obj[key] = " ";
        return null;
      });
      arr.push(obj);
      return null;
    });
  }
  const work_book = xls.utils.book_new();
  work_book.Props = {
    Title: title,
    Subject: subject,
    Author: author,
    CreatedDate: new Date(),
  };
  work_book.SheetNames.push(sheet_name);
  const sheet_data = xls.utils.aoa_to_sheet([
    ...headings,
    columns,
    ...arr?.map((row) => _.values(row)),
    footers,
  ]);
  sheet_data["!cols"] = fitToColumn([columns]);
  work_book.Sheets[sheet_name] = sheet_data;
  const work_book_export = xls.write(work_book, {
    bookType: "xlsx",
    type: "binary",
  });
  FileSaver.saveAs(
    new Blob([s2ab(work_book_export)], { type: "application/octet-stream" }),
    `${file_name}.xlsx`,
  );
};
export const getvalidDateDMY = (date) => {
  if (date === "" || date === undefined) {
    return "";
  } else {
    const resdate = new Date(date);
    const year = resdate.getFullYear();
    const month =
      (resdate.getMonth() + 1).toString().length === 1
        ? "0" + (resdate.getMonth() + 1)
        : (resdate.getMonth() + 1).toString();
    const day =
      resdate.getDate().toString().length === 1
        ? "0" + resdate.getDate()
        : resdate.getDate().toString();
    return day + "-" + month + "-" + year;
  }
};

export const handleExportToDoc = (filename = "gcf_concept_note_draft") => {
  const preHtml =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  const postHtml = "</body></html>";

  const html =
    preHtml +
    document.getElementById("exportContent")?.innerHTML.replace(/\n/g, "<br>") +
    postHtml;

  // Specify link url
  const url =
    "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
  // Specify file name
  filename = filename ? filename + ".doc" : "document.doc";
  // Create download link element
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);
  // Create a link to the file
  downloadLink.href = url;
  // Setting the file name
  downloadLink.download = filename;
  //triggering the function
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

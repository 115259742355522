const AUTH_TOKEN = "token";
const MASTER_AUTH_TOKEN = "master_token";

export function getTokenFromCookies() {
  return localStorage.getItem(AUTH_TOKEN) || "";
}
export function setTokenIntoCookies(token: string) {
  localStorage.setItem(AUTH_TOKEN, token);
}
export function flushTokenFromCookies() {
  localStorage.removeItem(AUTH_TOKEN);
}
export function getAuthorizationHeader() {
  return `Bearer ${getTokenFromCookies()}`;
}

export function getMasterTokenFromCookies() {
  return localStorage.getItem(MASTER_AUTH_TOKEN) || "";
}

export function flushMasterTokenFromCookies() {
  localStorage.removeItem(MASTER_AUTH_TOKEN);
}
export function getMasterAuthorizationHeader() {
  return `Bearer ${getMasterTokenFromCookies()}`;
}

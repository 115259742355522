import down from "@assets/images/down.svg";
import { CommonInput } from "@components/common-components/input";
import { useTokensContext } from "@components/layout/main-layout/private-layout";
import QusestionSubmitionModal from "@components/pages/project/common-components/qusestion-submition-modal";
import { wordLimits } from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/utils/wordLimits";
import { useStore } from "@stores/root-store";
import { validateMessages } from "@utils/json-data";
import { constRoute } from "@utils/route";
import { Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { observer } from "mobx-react";
import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CommonFooterButton from "../commonfooterbutton";
import CommonImportantSideBar from "../importantSideBar/common-important-sidebar";
import "./style.css";
import style from "./style.module.scss";

const ContextAndBaselineForm = observer(() => {
  const location = useLocation();
  const [form] = useForm();
  const [submitModalShow, setSubmitModalShow] = useState(false);
  const navigate = useNavigate();
  const [initialQuestions, setInitialQuestions] = useState(null);

  const {
    user: {
      getProjectNameData,
      getLoadingConceptNote,
      getProjectDataList,
      conceptNote,
      setConceptNoteLoading,
      getSingleProjectData,
      loadingSingleProjectData,
      handleCheckInputTokensValid,
    },
  } = useStore(null);
  const { setTokens } = useTokensContext();
  const [projectName] = useState(JSON.parse(getProjectNameData)?.project_name);
  const [isLoading, setIsLoading] = useState(true);
  const [allInputValues, setAllInputValues] = useState({
    q1a: "",
    q1b: "",
    q1c: "",
    q2: "",
    q3: "",
  });
  const [isValid, setIsValid] = useState(true);
  const getProjectName = localStorage.getItem("projectName");

  const checkLengthOfInput = async (value: string) => {
    const data = {
      functionality: "concept note",
      section: "B_1_0",
      questions: { ...allInputValues },
      project_name: projectName || getProjectName,
    };

    await handleCheckInputTokensValid(data).then((res) => {
      const result = JSON.parse(res.isValid);
      setIsValid(result);
    });
  };

  useEffect(() => {
    const value = Object.values(allInputValues).join(" ");
    checkLengthOfInput(value);
  }, [allInputValues]);

  useEffect(() => {
    if (localStorage.getItem("AllAnswers") === null) {
      localStorage.setItem(
        "AllAnswers",
        JSON.stringify([{ q1a: "", q1b: "", q1c: "", q2: "", q3: "" }]),
      );
    }
    if (localStorage.getItem("allResults") === null) {
      localStorage.setItem("allResults", JSON.stringify([{ result1: "" }]));
    }
  }, []);

  const onFormSubmit = async (values) => {
    // setSubmitModalShow(true);
    localStorage.setItem("isSocketOn", "1");
    localStorage.setItem(
      "AllAnswers",
      JSON.stringify([
        {
          q1a: values?.q1a || "",
          q1b: values?.q1b || "",
          q1c: values?.q1c || "",
          q2: values?.q2 || "",
          q3: values?.q3 || "",
        },
      ]),
    );
    const question = {
      q1_a: values?.q1a || "",
      q1_b: values?.q1b || "",
      q1_c: values?.q1c || "",
      q2: values?.q2 || "",
      q3: values?.q3 || "",
    };
    const payload = {
      section: `B_1_0`,
      questions: question,
      project_name: projectName || getProjectName || "",
    };

    localStorage.setItem("conceptPayload", JSON.stringify(payload));
    const getReultsfromls = JSON.parse(localStorage.getItem("totalResults"));
    const addResults =
      getReultsfromls &&
      getReultsfromls?.map((item) => {
        return {
          ...item,
          result1: "",
        };
      });
    localStorage.setItem("totalResults", JSON.stringify(addResults));
    // const response = await conceptNote(payload, navigate);
    // if (response?.response) {
    navigate(constRoute?.contextAndBaselineResults);
  };
  const getAnswers = JSON.parse(localStorage.getItem("AllAnswers"));
  const handleSave = () => {
    setConceptNoteLoading(false);
    // notification.success("Save and Quit");
    navigate(constRoute?.home);
  };
  const handleback = () => {
    localStorage.setItem("isSocketOn", "0");
    setConceptNoteLoading(false);
    navigate(constRoute?.projectName);
  };

  useEffect(() => {
    const data = {
      section: "B_1_0",
      project_name: projectName ?? localStorage.getItem("projectName"),
      functionality: "concept note",
    };
    localStorage.setItem("data-for-tokens", JSON.stringify(data));
    getSingleProjectData(data).then((el) => {
      setTokens(el["tokens_remaining/tokens_purchased"]);
      setInitialQuestions(el);
      setIsLoading(false);
      form.setFieldsValue(el.questions);
    });
  }, []);

  return (
    <div className={style.mainContainer}>
      <div className={style.barContentContainer}>
        <CommonImportantSideBar
          title={"Please Note: "}
          fristPara={`1. For mitigation projects, discuss the contribution to the shift to low-emission sustainable development pathways.`}
          secondParagraph={`2. For adaptation projects, discuss the contribution to increased climate-resilient sustainable development for most vulnerable people and communities. `}
          thirdParagraph={`3. Be sure to include the number of beneficiaries the project / programme is expected to help. `}
          forthParagraph={`4. Remember, the GCF is interested in “turning barriers into significant opportunities to catalyse financing more effectively, and ultimately to bring projects/ programmes to scale.”`}
        />
        <div
          className={style.contentContainer}
          style={{ cursor: isLoading ? "wait" : "" }}
        >
          <div className={style.innerContentContainer}>
            <h1>Please Fill In the Blanks Below:</h1>

            <div className={style.dataContentBox}>
              <Form
                className={style.formData}
                form={form}
                autoComplete="false"
                onFinish={onFormSubmit}
                validateMessages={validateMessages}
                layout="vertical"
                initialValues={{
                  q1a:
                    getProjectDataList?.q1a ||
                    initialQuestions?.questions?.q1a ||
                    (getAnswers && getAnswers[0]?.q1a) ||
                    "",
                  q1b:
                    getProjectDataList?.q1b ||
                    initialQuestions?.questions?.q1b ||
                    (getAnswers && getAnswers[0]?.q1b) ||
                    null,
                  q1c:
                    getProjectDataList?.q1c ||
                    initialQuestions?.questions?.q1c ||
                    (getAnswers && getAnswers[0]?.q1c) ||
                    "",
                  q2:
                    getProjectDataList?.q2 ||
                    initialQuestions?.questions?.q2 ||
                    (getAnswers && getAnswers[0]?.q2) ||
                    "",
                  q3:
                    getProjectDataList?.q3 ||
                    initialQuestions?.questions?.q3 ||
                    (getAnswers && getAnswers[0]?.q3) ||
                    "",
                }}
              >
                <div className={style.questionOne}>
                  <Form.Item
                    label={
                      <span>
                        {"1a). Project region or country name? "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name={"q1a"}
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <CommonInput
                      inputType="textarea"
                      disabled={isLoading}
                      onChange={(e) =>
                        setAllInputValues((prev) => {
                          return { ...prev, q1a: e.target.value };
                        })
                      }
                      autoSizeCheck={{ minRows: 7, maxRows: 7 }}
                      className={style.emailInput}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ paddingBottom: "8px" }}>
                        {"1b). What kind of project? "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name={"q1b"}
                    rules={[
                      { required: true, message: "This field is required" },
                    ]}
                  >
                    <Select
                      defaultValue="Select project"
                      placeholder="Select project"
                      className="height-50 ant-select-selector ant-select-selection-placeholder"
                      style={{
                        width: "318px",
                        height: "50px !important",
                        textAlign: "start",
                        padding: "0",
                      }}
                      disabled={isLoading}
                      onChange={(value) =>
                        setAllInputValues((prev) => {
                          return { ...prev, q1b: value };
                        })
                      }
                      suffixIcon={
                        <img
                          style={{ marginRight: "0", marginBottom: "6px" }}
                          src={down}
                          alt={"down icon"}
                        />
                      }
                      options={[
                        {
                          value: "Adaptation",
                          label: "Adaptation",
                        },
                        {
                          value: "Mitigation",
                          label: "Mitigation",
                        },
                      ]}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <span style={{ marginBottom: "8px" }}>
                        {"1c). Sector to be addressed "}
                        <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    name={"q1c"}
                  >
                    <Select
                      defaultValue="Select project"
                      placeholder="Select project"
                      className="height-50 ant-select-selector ant-select-selection-placeholder"
                      disabled={isLoading}
                      style={{
                        width: "318px",
                        height: "50px !important",
                        textAlign: "start",
                        padding: "0",
                      }}
                      onChange={(value) =>
                        setAllInputValues((prev) => {
                          return { ...prev, q1b: value };
                        })
                      }
                      suffixIcon={
                        <img
                          style={{ marginBottom: "6px" }}
                          src={down}
                          alt={"down icon"}
                        />
                      }
                      options={[
                        {
                          value:
                            "Buildings, cities, industries, and appliances",
                          label:
                            "Buildings, cities, industries, and appliances",
                        },
                        {
                          value: "Ecosystems and ecosystem services",
                          label: "Ecosystems and ecosystem services",
                        },
                        {
                          value: "Energy generation and access",
                          label: "Energy generation and access",
                        },
                        {
                          value: "Forests and land use",
                          label: "Forests and land use",
                        },
                        {
                          value: "Health, food, and water security",
                          label: "Health, food, and water security",
                        },
                        {
                          value: "Infrastructure and built environment",
                          label: "Infrastructure and built environment",
                        },
                        {
                          value: "Livelihoods of people and communities",
                          label: "Livelihoods of people and communities",
                        },
                        {
                          value: "Transport",
                          label: "Transport",
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  label={
                    <span>
                      {
                        "2. Describe the climate vulnerabilities and impacts, GHG emissions profile, and mitigation and adaptation needs that the prospective intends to address."
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q2"}
                  rules={[
                    {
                      required: true,
                      message: "This field is required",
                    },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    disabled={isLoading}
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q2: e.target.value };
                      })
                    }
                    placeholder=""
                    autoSizeCheck={{ minRows: 5, maxRows: 5 }}
                    className={style.fontSizeInput}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span>
                      {
                        "3. Describe the main root causes and barriers (social, gender, fiscal, regulatory, technological, financial, ecological, institutional, etc.) that need to be addressed."
                      }
                      <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name={"q3"}
                  rules={[
                    { required: true, message: "This field is required" },
                  ]}
                >
                  <CommonInput
                    inputType="textarea"
                    disabled={isLoading}
                    onChange={(e) =>
                      setAllInputValues((prev) => {
                        return { ...prev, q3: e.target.value };
                      })
                    }
                    autoSizeCheck={{ minRows: 5, maxRows: 5 }}
                    className={style.fontSizeInput}
                  />
                </Form.Item>
              </Form>
              {!isValid && (
                <div
                  style={{
                    color: "red",
                    fontSize: "14px",
                    position: "relative",
                    top: "45px",
                  }}
                >
                  You have exceeded the maximum length for this field
                </div>
              )}

              <div
                style={{
                  paddingTop: "5px",
                  position: "relative",
                  top: !isValid ? "40px" : "30px",
                }}
              >
                The max word limit for this page is {wordLimits[0]} words
              </div>
            </div>
            {/* <div className={style.footerButtonsDiv}>
              <Form form={form} onFinish={onFormSubmit}>
                <Button
                  loading={getLoadingConceptNote}
                  disabled={getLoadingConceptNote}
                  htmlType="submit"
                  className={style.nextButton}
                >
                  Submit
                </Button>
              </Form>
              <div className={style.btnDiv}>
                <div className={style.twoBtnDiv}>
                  <button
                    className={style.goBtn}
                    onClick={() => navigate(constRoute?.projectName)}
                  >
                    <img src={LeftArrow} alt="left-arrow" /> Go Back
                  </button>
                  <button
                    onClick={() => {
                      notification.success("Save and Quit");
                      navigate(constRoute?.home);
                    }}
                    className={style.saveBtn}
                  >
                    Save & Quit
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <CommonFooterButton
            isLoadingSubmit={getLoadingConceptNote}
            handleSubmit={onFormSubmit}
            handlegoback={() => {
              navigate(constRoute.projectName + "/draft");
            }}
            handleSaveAndQuit={handleSave}
            // handleQuickNext={constRoute?.contextAndBaselineResults}
            form={form}
            disableSubmit={!isValid || isLoading}
          />
        </div>
        <QusestionSubmitionModal open={submitModalShow} />
      </div>
    </div>
  );
});

export default memo(ContextAndBaselineForm);

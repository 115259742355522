import { constRoute } from "@utils/route";
import { useNavigate } from "react-router-dom";

import style from "./style.module.scss";
import greenClimateFundImage from "@assets/pages/select-donor/green_climate_fund.png";
import globalEnvironmentFacilityImage from "@assets/pages/select-donor/global_environment_facility.png";
import adaptationFundImage from "@assets/pages/select-donor/adaptation_fund.png";

import GoBack from "@components/common-components/go-back";

const SelectDonor = () => {
  const navigate = useNavigate();

  const btnCardsList = [
    {
      name: "Green Climate Fund",
      navigate: () => navigate(constRoute?.selectFunction),
      img: <img src={greenClimateFundImage} />,
      disabled: false,
      comingSoon: false,
    },
    {
      name: "Global Environment Facility",
      navigate: () => navigate(constRoute?.selectFunction),
      img: <img src={globalEnvironmentFacilityImage} />,
      disabled: true,
      comingSoon: true,
    },
    {
      name: "Adaptation Fund",
      navigate: () => navigate(constRoute?.selectFunction),
      img: <img src={adaptationFundImage} />,
      disabled: true,
      comingSoon: true,
    },
  ];

  return (
    <div className={style.donorContainer}>
      <h2>Select a donor below</h2>

      <div className={style.donorContainerCardsContainer}>
        {btnCardsList.map((crd) => (
          <div
            key={crd.name}
            onClick={crd.disabled ? null : crd.navigate}
            className={crd.disabled ? style.disabled : null}
          >
            {crd.comingSoon && (
              <div className={style.comingSoon}>Coming Soon</div>
            )}
            {crd.img}
          </div>
        ))}
      </div>
      <div className={style.btnDiv}>
        <div className={style.twoBtnDiv}>
          <div className={style.goBackBtnDiv}>
            <GoBack onClick={constRoute?.home} className={style.goBtn} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDonor;

import React, { memo, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import loadingIcon from "@assets/icons/Loading4.svg";
import style from "./style.module.scss";
interface modalProps {
  open?: any;
  setOpenModal?: any;
}

const QusestionSubmitionModal: React.FC<modalProps> = ({
  open,
  setOpenModal,
}) => {
  return (
    <Modal
      title=""
      footer={null}
      open={open}
      onCancel={() => {
        localStorage.removeItem("trialModal");
        setOpenModal(false);
      }}
    >
      <div style={{ margin: -24 }}>
        <div className={style.trialLogoWrraper}>
          <img src={loadingIcon} alt="loadingIcon" height={139} width={134} />
        </div>
        <div className={style.contentWrraper}>
          <p className={style.firstHead}>
            Your draft winning narrative is on the way, please be patient.{" "}
          </p>
          <p className={style.secondHead}>
            This process can take up to three minutes.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default memo(QusestionSubmitionModal);

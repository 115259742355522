import { FC } from "react";

type Props = {
  color?: string;
};

const DashboardIcon: FC<Props> = ({ color }) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.76634 11.0013H17.233M4.76634 11.0013C3.73958 11.0013 3.2262 11.0013 2.83403 10.8015C2.48906 10.6257 2.2086 10.3452 2.03283 10.0003C1.83301 9.60811 1.83301 9.09473 1.83301 8.06797V6.6013C1.83301 5.57454 1.83301 5.06116 2.03283 4.66899C2.2086 4.32402 2.48906 4.04356 2.83403 3.86779C3.2262 3.66797 3.73958 3.66797 4.76634 3.66797H17.233C18.2598 3.66797 18.7732 3.66797 19.1653 3.86779C19.5103 4.04356 19.7908 4.32402 19.9665 4.66899C20.1663 5.06116 20.1663 5.57454 20.1663 6.6013V8.06797C20.1663 9.09473 20.1663 9.60811 19.9665 10.0003C19.7908 10.3452 19.5103 10.6257 19.1653 10.8015C18.7732 11.0013 18.2598 11.0013 17.233 11.0013M4.76634 11.0013C3.73958 11.0013 3.2262 11.0013 2.83403 11.2011C2.48906 11.3769 2.2086 11.6574 2.03283 12.0023C1.83301 12.3945 1.83301 12.9079 1.83301 13.9346V15.4013C1.83301 16.4281 1.83301 16.9414 2.03283 17.3336C2.2086 17.6786 2.48906 17.959 2.83403 18.1348C3.2262 18.3346 3.73958 18.3346 4.76634 18.3346H17.233C18.2598 18.3346 18.7732 18.3346 19.1653 18.1348C19.5103 17.959 19.7908 17.6786 19.9665 17.3336C20.1663 16.9414 20.1663 16.4281 20.1663 15.4013V13.9346C20.1663 12.9079 20.1663 12.3945 19.9665 12.0023C19.7908 11.6574 19.5103 11.3769 19.1653 11.2011C18.7732 11.0013 18.2598 11.0013 17.233 11.0013M10.9997 7.33464H16.4997M10.9997 14.668H16.4997M7.33301 7.33464C7.33301 7.8409 6.9226 8.2513 6.41634 8.2513C5.91008 8.2513 5.49967 7.8409 5.49967 7.33464C5.49967 6.82837 5.91008 6.41797 6.41634 6.41797C6.9226 6.41797 7.33301 6.82837 7.33301 7.33464ZM7.33301 14.668C7.33301 15.1742 6.9226 15.5846 6.41634 15.5846C5.91008 15.5846 5.49967 15.1742 5.49967 14.668C5.49967 14.1617 5.91008 13.7513 6.41634 13.7513C6.9226 13.7513 7.33301 14.1617 7.33301 14.668Z"
        stroke={color || "#000"}
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default DashboardIcon;

import { ProjectStructuring } from "@assets/icons/double";
import img1 from "@assets/images/selectFun1.svg";
import img2 from "@assets/images/selectFun2.svg";
import img4 from "@assets/images/selectFun4.svg";
import img5 from "@assets/images/selectFun5.svg";
import img7 from "@assets/images/selectFun7.svg";
import img8 from "@assets/images/selectFun8.svg";
import img9 from "@assets/images/selectFun9.svg";
import GoBack from "@components/common-components/go-back";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import CardItemsFuncImg from "../common-components/card-item-func-with-img";
import style from "./style.module.scss";

const SelectFunction = observer(() => {
  const navigate = useNavigate();

  const {
    user: { getUserType },
  } = useStore(null);

  let userType = getUserType;

  userType = "enterprise";

  const functionEvalDraft = (() => {
    // switch (userType) {
    //   case "basic":
    //     return "Draft a Concept Note";
    //   case "premium":
    //     return "Full Proposal Evaluator";
    //   case "enterprise":
    //     return "Full Proposal Evaluator";
    //   default:
    //     return "Draft a Concept Note or Proposal";
    // }
    return "Full Proposal Evaluator";
  })();

  const functionImgDraft = (() => {
    // switch (userType) {
    //   case "basic":
    //     return img2;
    //   case "premium":
    //     return img2;
    //   case "enterprise":
    //     return img2;
    //   default:
    //     return img1;
    // }

    return img2;
  })();

  const btnCardsList = [
    {
      title: "Evaluate a Concept Note",
      status: "",
      disable: false,
      navigate: () => navigate("/project-name/evaluate"),
      img: functionImgDraft,
      opacity: "100%",
    },
    {
      title: functionEvalDraft,
      disable: userType === "enterprise",
      status: userType === "enterprise" ? "Coming Soon" : "",
      navigate: () =>
        userType !== "enterprise"
          ? navigate("/project-name/draft", {
              state: { feature: true },
            })
          : null,
      img: userType !== "enterprise" ? img1 : img5,
      opacity: "100%",
    },
    {
      title: "Climate Rationale Advisor",
      status: "",
      navigate: () =>
        navigate(constRoute.rationalAdvisorWelcome, {
          state: { feature: true },
        }),
      disable: false,
      img: img4,
      opacity: "100%",
    },
    {
      title:
        userType === "enterprise"
          ? "Draft a Concept Note"
          : "Climate Rationale Advisor",
      status: userType === "enterprise" ? "" : "Coming Soon",
      disable: userType !== "enterprise",
      navigate: () =>
        navigate("/project-name/draft", {
          state: { feature: true },
        }),
      img: userType === "enterprise" ? img1 : img4,
      opacity: userType === "enterprise" ? "100%" : "50%",
    },
    {
      title: "Theory of Change",
      status: "",
      disable: false,
      img: img8,
      opacity: "",
      navigate: () =>
        navigate(constRoute.theoryOfChangeWelcome, {
          state: { feature: true },
        }),
    },
    {
      title: "Renewable Energy Project Structuring",
      status: "",
      disable: false,
      img: img7,
      navigate: () =>
        navigate(constRoute.projectStructureWelcome, {
          state: { feature: true },
        }),
      opacity: "",
    },
    {
      title: "Annexes and Other Documents",
      disable: userType === "enterprise",
      status: userType === "enterprise" ? "Coming Soon" : "",
      navigate: () =>
        userType !== "enterprise"
          ? navigate("/project-name/draft", {
              state: { feature: true },
            })
          : null,
      img: userType !== "enterprise" ? img1 : img9,
      opacity: "100%",
    },

    // {
    //   title: "Renewable Energy Project Structuring",
    //   status: "",
    //   disable: false,
    //   img: img7,
    //   navigate: () =>
    //     navigate(constRoute.projectStructureWelcome, {
    //       state: { feature: true }
    //     }),
    //   opacity: "100%"
    // },
    // {
    //   title: "Create Project Structure Plan",
    //   status: "Coming Soon",
    //   disable: true,
    // },
    // {
    //   title: "Funder Matchmaker ",
    //   status: "Coming Soon",
    //   disable: true,
    // },
    // {
    //   title: "Draft An Annex",
    //   status: "Coming Soon",
    //   disable: true,
    // },
  ];

  const btnCardsListArr = (() => {
    // switch (userType) {
    //   case "basic":
    //     return [
    //       btnCardsList[5],
    //       btnCardsList[4],
    //       btnCardsList[1],
    //       btnCardsList[0],
    //     ];
    //   // case "premium":
    //   //   return [...btnCardsList.slice(0, 3), ...btnCardsList.slice(-2)];
    //   case "enterprise":
    //     const enterpriseDefault = [
    //       btnCardsList[2],
    //       btnCardsList[4],
    //       btnCardsList[3],
    //       btnCardsList[0],
    //     ];
    //
    //     enterpriseDefault.push(btnCardsList[5]);
    //
    //     enterpriseDefault.push(btnCardsList[1]);
    //     return enterpriseDefault;
    //   default:
    //     return [...btnCardsList.slice(0, 2), ...btnCardsList.slice(-2)];
    // }
    const enterpriseDefault = [
      btnCardsList[2],
      btnCardsList[4],
      btnCardsList[3],
      btnCardsList[0],
    ];

    enterpriseDefault.push(btnCardsList[5]);

    enterpriseDefault.push(btnCardsList[1]);
    return enterpriseDefault;
  })();

  return (
    <div>
      <div className={style.homePagePageContainer}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflowY: "scroll",
            padding: "60px 0 100px 0",
          }}
        >
          <div className={style.homePageContainerCol} style={{ width: "70%" }}>
            <div className={style.homePageContainer}>
              <div className={style.headerContainer}>
                <h1
                  style={{
                    textAlign: "center",
                    fontSize: "34px",
                    fontWeight: "500",
                    lineHeight: "42px",
                  }}
                >
                  Select a function below
                </h1>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  width: "fit-content",
                  flexWrap: "wrap",
                  margin: "0 auto",
                  maxWidth: userType !== "enterprise" ? "890px" : "",
                  // gridTemplateColumns:
                  //   userType === "enterprise" ? "1fr 1fr 1fr" : "1fr 1fr",
                }}
                className={style.boxesContiner}
              >
                {btnCardsListArr?.map((item) => {
                  return <CardItemsFuncImg cardGrid={true} item={item} />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.btnDiv}>
        <div className={style.twoBtnDiv}>
          <div className={style.goBackBtnDiv}>
            <GoBack onClick={constRoute?.selectDonor} className={style.goBtn} />
          </div>

          {/* <button className={style.goBtn}  onClick={() =>
           navigate(constRoute?.home)}> <img src={LeftArrow} alt="left-arrow" /> Go Back</button> */}
          {/* <button
                 onClick={() => {
                   notification.success("Save and Quit");
                   navigate(constRoute?.home);
                 }}
                 className={style.saveBtn}
               >
                 Save & Quit
               </button> */}
        </div>
      </div>
    </div>
  );
});

export default SelectFunction;

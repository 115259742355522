import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import {
  useNarrativesContext,
  useTokensContext,
} from "@components/layout/main-layout/private-layout";
import AdvisorForm from "@components/pages/project/common-components/advisor-form";
import CommonFooterButton from "@components/pages/project/select-function/which-to-do/select-one/project-name/important-project-info/commonfooterbutton";
import { useStore } from "@stores/root-store";
import { constRoute } from "@utils/route";
import { useForm } from "antd/es/form/Form";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import { DataType } from "types/data-type";
import { baseSocketUrl } from "@api/const";

interface AdvisorFormProps {}
const formQuestions = [
  {
    q: <span>Tell Us about the Project below:</span>,
    result: <span>Your Results Below:</span>,
    limit: 1000,
    placeholder:
      "Here are some questions to get you started:  \n" +
      "\n" +
      "• What is the primary type of renewable energy being considered (e.g., solar, wind, hydro, geothermal)?\n" +
      "• What is the estimated capacity (in MW) of the project?\n" +
      "• What's the intended geographical location for the project?",
  },
  {
    q: <>Tell Us About the Project’s Feasibility and Site Assessment Below:</>,
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder:
      "Here are some questions to get you started:  \n" +
      "\n" +
      "• Have preliminary feasibility studies been conducted?\n" +
      "• Are there any known environmental or logistical challenges in the chosen location?\n" +
      "• What is the availability of resources (like solar irradiance for solar projects, wind speeds for wind projects, etc.)?",
  },
  {
    q: <>Tell Us About the project’s Financial and Economic Condition Below:</>,
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder:
      "Here are some questions to get you started:  \n" +
      "\n" +
      "• What is the estimated project cost?\n" +
      "• What are the expected sources of funding or financing mechanisms (e.g., grants, loans, equity)?\n" +
      "• Have you conducted a cost-benefit analysis or Internal Rate of Return (IRR) projection?",
  },
  {
    q: (
      <>
        Tell Us About The Project’s Regulatory and Compliance framework below:
      </>
    ),
    result: <>Your Results Below:</>,
    placeholder:
      "Here are some questions to get you started:\n" +
      "\n" +
      "• What are the local and national regulations pertaining to your renewable energy project?\n" +
      "• Have you secured or begun the process to secure necessary permits?\n" +
      "• Are there any incentives or tax benefits for renewable projects in the targeted region?",
    limit: 1000,
  },
  {
    q: <>Tell Us About The Project’s Implementation and Timeline Below:</>,
    result: <>Your Results Below:</>,
    limit: 1000,
    placeholder:
      "Here are some questions to get you started. \n" +
      "\n" +
      "• What is the anticipated project timeline, from inception to commissioning?\n" +
      "• Have you identified key stakeholders and partners for the project?\n" +
      "• What are the major milestones and what are the potential risks or challenges?",
  },
  {
    q: (
      <>
        Tell Us About The Project’s Operations, Maintenance and Monitoring
        Below:
      </>
    ),
    result: <>Your Results Below:</>,
    placeholder:
      "Here are some questions to get you started:\n" +
      "\n" +
      "• What is the planned operation and maintenance strategy post-commissioning?\n" +
      "• Are there arrangements in place for continuous monitoring of the project's performance?\n" +
      "• What are the plans for ensuring the project's resilience and efficiency in the long term?: ",
    limit: 1000,
  },
];

export const sectionsMap = [
  {
    text: "Project Identification and Scope",
    section: "pis",
  },
  {
    text: "Feasibility and Site Assesment",
    section: "fsa",
  },
  {
    text: "Financial and Economic Analysis",
    section: "fea",
  },
  {
    text: "Regulatory and Compliance",
    section: "rc",
  },
  {
    text: "Project Implementation and Timeline",
    section: "it",
  },
  {
    text: "Operations Maintenance and Monitoring",
    section: "omm",
  },
];
const sections = sectionsMap.map((section) => section.section);

const AdvisorPage: FC<AdvisorFormProps> = () => {
  const nav = useNavigate();
  const socketRef = useRef(null);
  const { type, step } = useParams();
  const { setTokens } = useTokensContext();
  const { advisorNarratives, setAdvisorNarratives } = useNarrativesContext();
  const [form] = useForm();
  const {
    user: { getSingleProjectData, projectNameData, loadGetAdvisorResults },
    modelType: { getSelectedModelType },
  } = useStore(null);

  const projectName =
    (projectNameData && JSON.parse(projectNameData).project_name) ||
    localStorage.getItem("projectName");

  const [initialInput, setInitialInput] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [resultsData, setResultsData] = useState("");
  const [isAnswer, setIsAnswer] = useState(false);

  const results = isSubmitting
    ? ""
    : advisorNarratives.find((el) => el.section === sections[+step - 1])?.res;

  const email = localStorage.getItem("email");
  const isFormPage = type === "form";

  // useEffect(() => {
  //  const  data = {"section":"ch_15","questions":{"q1a":"jamaica","q1b":"adaptation","q2":"arar","q3":"zfrfrf"},"project_name":"first project","email":"mohamed.ardif456@gmail.com"}
  //   let socket = io(baseUrl,{
  //     extraHeaders: {
  //       "Origin": "*:*" // allowing connections with empty origin either
  //     },
  //   });
  //   // socket.set('origins', '*');
  //   socket.emit('climateRationale',data);
  //   socket.on('answer',(answer) => {
  //     console.log(answer);
  //   })
  // }, []);

  const handleSubmit = useCallback(
    (values) => {
      if (!isValid) return;
      if (isFormPage) {
        setResultsData("");
        setIsSubmitting(true);

        const data: DataType = {
          section: sections[+step - 1],
          input: values?.q,
          project_name: projectName,
          email: email,
          model: getSelectedModelType ?? "GPT-4 (Turbo)",
        };

        const socket = io(baseSocketUrl, {
          transports: ["websocket", "polling"],
        });

        socketRef.current = socket;
        socketRef?.current?.emit("projectStructuring", data);

        socketRef?.current?.on("answer", (answer) => {
          const isStopGeneration = localStorage.getItem("isStopGeneration");

          if (!isSubmitting && answer) {
            setIsSubmitting(true);
          }

          if (answer) {
            setIsAnswer(true);
          }

          if (isStopGeneration || answer === "#####") {
            socket.disconnect();
            setIsSubmitting(false);
            setIsAnswer(false);
            localStorage.removeItem("isStopGeneration");
            return;
          }

          form.setFieldValue("q", (form?.getFieldValue("q") ?? "") + answer);
          localStorage.setItem(
            "totalResultsAdvisor" + projectName,
            JSON.stringify({
              ...results,
              [`result${step}`]: (form?.getFieldValue("q") ?? "") + answer,
            }),
          );
        });
        socket.on("disconnect", () => {
          localStorage.removeItem("isStopGeneration");
          setIsSubmitting(false);
          setIsAnswer(false);
        });
        nav(constRoute?.advisorProject + "/results/" + step);
      } else {
        if (formQuestions.length < +step + 1) {
          nav(constRoute?.gcfCongratulation + "/advisor");
          return;
        }
        setIsSubmitting(false);
        setIsAnswer(false);
        nav(constRoute?.advisorProject + "/form/" + (+step + 1));
      }
    },
    [type, step, getSelectedModelType],
  );

  const handleGoBack = () => {
    socketRef?.current?.disconnect();
    setIsSubmitting(false);
    setIsAnswer(false);
    // if (isSubmitting) return;
    if (+step === 1 && type == "results") {
      nav(constRoute?.advisorProject + "/form/1");

      return;
    }

    if (+step < 2) {
      nav(constRoute.projectName + "/advisor");
      return;
    }

    if (type === "results") {
      nav(constRoute?.advisorProject + "/form/" + +step);
    } else {
      nav(constRoute?.advisorProject + "/results/" + (+step - 1));
    }
  };

  const handleSaveQuit = () => {
    nav(constRoute?.existingProject);
  };

  const getProjectData = async () => {
    const data = {
      section: sections[+step - 1].toLowerCase(),
      project_name: projectName,
      functionality: "project structuring",
    };
    setIsLoading(true);
    localStorage.setItem("data-for-tokens", JSON.stringify(data));
    await getSingleProjectData(data, null)
      .then((state) => {
        if (isFormPage) {
          form.setFieldValue("q", state?.questions.input);
        } else {
          if (!isSubmitting) {
            form.setFieldValue(`q`, state?.generated_narratives?.find(Boolean));
          }
        }
        setTokens(state["tokens_remaining/tokens_purchased"]);
        setAdvisorNarratives((prev) =>
          prev.map((el) => {
            if (el.section === sections[+step - 1]) {
              return {
                ...el,
                res: state.generated_narratives.map((el) => el).join("") || "",
              };
            } else {
              return el;
            }
          }),
        );
        setInitialInput(state?.questions);
      })
      .finally(() => setIsLoading(false));

    if (+step < 1 || formQuestions.length + 1 <= +step) {
      nav(constRoute?.gcfCongratulation + "/advisor");
    }
  };

  const handleStopGenerate = () => {
    if (socketRef) {
      socketRef.current.disconnect();
    }
    setIsSubmitting(false);
    setIsAnswer(false);
  };

  useEffect(() => {
    localStorage.removeItem("isStopGeneration");
    getProjectData();
  }, [step, type]);
  useEffect(() => {
    form.resetFields();
  }, [type === "results"]);

  useEffect(() => {
    socketRef?.current?.disconnect();
    form.resetFields();
    setIsSubmitting(false);
    setIsAnswer(false);
    setResultsData(
      advisorNarratives.find((el) => el.section === sections[+step - 1])?.res ||
        "",
    );
  }, [step]);

  useEffect(() => {
    return () => {
      socketRef?.current?.disconnect();
    };
  }, []);

  return (
    <div style={{ padding: "30px 52px 122px" }}>
      <AdvisorForm
        form={form}
        value={results}
        isSubmitting={isSubmitting}
        initialState={initialInput}
        handleSubmit={handleSubmit}
        isFormPage={isFormPage}
        Text={
          isFormPage
            ? formQuestions[+step - 1]?.q
            : formQuestions[+step - 1]?.result || results[`result${step}`] || ""
        }
        placeholder={formQuestions[+step - 1]?.placeholder}
        maxLength={formQuestions[+step - 1]?.limit}
        disabled={type === "results"}
        section={sections[+step - 1]}
        setIsValid={setIsValid}
        isValid={isValid}
        handleStopGenerate={handleStopGenerate}
        isLoading={isLoading}
        isAnswer={isAnswer}
      />
      <CommonFooterButton
        customStyle={{ left: 0 }}
        isLoadingSubmit={false}
        handleSubmit={handleSubmit}
        handlegoback={handleGoBack}
        isSubmitting={isSubmitting}
        handleSaveAndQuit={handleSaveQuit}
        isSubmit={type === "results"}
        // handleQuickNext={constRoute?.contextAndBaselineResults}
        form={form}
        setIsSubmitting={setIsSubmitting}
        disableSubmit={!isValid || isLoading}
      />
    </div>
  );
};

export default memo(observer(AdvisorPage));
